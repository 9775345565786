<template>
    <br>
    <br><br>
    <p class="hidden">Bienvenue {{ userName }}</p>
    <p class="hidden">{{ iduser }}</p>
    <p class="hidden">{{ typeAbonnement }}</p>

    <p class="hidden">{{ userEmail }}</p>
    <p class="hidden">{{ userContact }}</p>
    <div @click="hide, menu_mobile2 = false" class="w-screen ">

        <br>
        <div>
            <div class="w-screen flex justify-between items-center carousel bg-white py-2 max-sm:px-5 sm:px-12">
                <!-- <div class="carousel-item w-screen"> -->
                <div class="md:w-1/2 max-md:hidden lg:block flex justify-start items-center">
                    <div class="flex justify-start items-center h-full">
                        <div class="h-fit">
                            <h1 class="text-left p-2 font-bold max-sm:text-lg sm:text-2xl lg:text-5xl">Agribusiness</h1>
                            <p class="text-left max-sm:text-sm text-gray-600">Bienvenue sur notre plateforme dédiée à l'information agricole, où les données les plus récentes se combinent à des analyses approfondies pour éclairer vos décisions et optimiser vos rendements. <br> Nous permettons de fournir
                                des divers informations agricoles et des conseils pratiques sur ce domaine. <br>
                                <br>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="md:w-1/2  max-md:hidden ">
                    <div class=" w-full"><img src="../../assets/img.jpeg " alt="image panel" class="clip-path-polygon"></div>
                </div>
                <div class="w-screen md:hidden  flex-col justify-center items-center">
                    <div class="max-md:screen lg:w-screen  max-md:block lg:hidden ">
                        <div class="w-11/12"><img src="../../assets/img.jpeg " alt="image panel" class="max-sm:clip-path-polygon "></div>
                    </div>
                    <div class="max-md:block lg:hidden  justify-start items-center ">
                        <div class="flex justify-start items-center h-full">
                            <div class="h-fit">
                                <h5 class="text-left p-2 font-bold mt-4">Agribusiness</h5>
                                <p class="text-left max-sm:text-sm text-gray-600">Bienvenue sur notre plateforme dédiée à l'information agricole, où les données les plus récentes se combinent à des analyses approfondies pour éclairer vos décisions et optimiser vos rendements. <br> Nous permettons de fournir
                                    des divers informations agricoles et des conseils pratiques sur ce domaine. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br><br>
        <div class="flex w-screen  flex-col justify-center items-center ">

            <div id="Service" class=" w-screen  bg-slate-50">
                <h2 class="max-sm:text-xl sm:text-3xl font-semibold">Service</h2>
                <div class="mx-10">
                    <p class=" max-sm:text-sm text-justify  text-gray-600 ">
                        Explorez le monde de l'agriculture avec nos informations exclusives, vous offrant des analyses approfondies, des client et fournisseurs des produits agricoles, nos services de conseils en gestion agricole pour optimiser la rentabilité de votre exploitation.
                        Découvrez la différence avec notre service exceptionnel, dédiée à surpasser vos attentes et à ouvrir de nouvelles perspectives dans votre domaine.
                    </p>
                    <br>
                </div>
                <div class="grid max-md:grid-cols-none max-lg:grid-cols-2 lg:grid-cols-3 w-fit  gap-3 text-sm py-8 sm:text-base justify-center items-start max-sm:px-5 sm:px-16">
                    <div class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300  items-start justify-center gap-3 ">
                        <div class="w-full flex justify-start items-center">
                            <div class="w-full flex items-center max-sm:gap-x-5 sm:gap-x-9">
                                <div class="fas fa-user fa-3x"></div>
                                <div>
                                    <h5 class=" max-sm:text-lg sm:text-xl font-bold">Client</h5>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mx-2">
                            <br>
                            <div class="text-left">
                                <p class="text-gray-600">
                                    Découvrez notre large clientèle diversifiée, englobant tous les domaines de l'agriculture et de l'élevage, qui veulent des produits agricoles et élevages.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                        <div class="w-full flex justify-start items-center">
                            <div class="w-full flex items-center gap-x-9">
                                <div class="fas fa-hand-holding-hand fa-3x"></div>
                                <div>
                                    <h5 class=" max-sm:text-lg sm:text-xl font-bold">Fournisseur</h5>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mx-2">
                            <br>
                            <div class="text-left">
                                <p class="text-gray-600">
                                    Découvrez une multitude de fournisseur dans tous les domaines de l'agriculture et de l'élevage sur notre plateforme, qui ont des produits agricoles et élevages.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col p-3 sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                        <div class="w-full flex justify-start items-center">
                            <div class="w-full flex items-center justify-center max-sm:gap-x-4 sm:gap-x-8">
                                <div class="fas fa-seedling fa-3x md:pr-2"></div>
                                <div>
                                    <h5 class=" max-sm:text-lg sm:text-xl font-bold">Produit agricole</h5>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mx-4">
                            <br>
                            <div class="text-left">
                                <p class="text-gray-600">
                                    Explorez les divers étapes minutieuses de la culture, depuis la préparation du sol jusqu'à la récolte, pour vous offrir des produits cultivés avec passion et expertise.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                        <div class="w-full flex justify-start items-center">
                            <div class="w-full flex items-center gap-x-9">
                                <div class="fas fa-cow fa-3x md:pr-2"></div>
                                <div>
                                    <h5 class=" max-sm:text-lg sm:text-xl font-bold">Produit elevage</h5>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mx-4">
                            <br>
                            <div class="text-left">
                                <p class="text-gray-600">
                                    Explorez les étapes respectueuses de notre processus d'élevage, où le confort, la nutrition, et la santé de nos animaux sont prioritaires.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                        <div class="w-full grid grid-cols-2flex justify-start items-center">
                            <div class="w-full flex items-center gap-x-9">
                                <div class="fas fa-chart-line fa-3x"></div>
                                <div>
                                    <h5 class=" max-sm:text-lg sm:text-xl font-bold">Courbe</h5>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mx-4">
                            <br>
                            <div class="text-left">
                                <p class="text-gray-600">
                                    Vous pouvez accéder au courbe montrant les prix des différents produits agricoles des année précedents qui vous aidera à prendre des décisions sur les marchés.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                        <div class="w-full flex justify-start items-center">
                            <div class="w-full flex items-center gap-x-9">
                                <div class="fas fa-calendar-days fa-3x"></div>
                                <div>
                                    <h5 class=" max-sm:text-lg sm:text-xl font-bold">Calendrier agricole</h5>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mx-4">
                            <br>
                            <div class="text-left">
                                <p class="text-gray-600">
                                    Découvrez notre calendrier agricole, un guide saisonnier qui vous accompagne au long de l'année avec les dates respectives pour les différents pratiques agricoles.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br>
            <div id="Propos">
                <h2 class="text-xl sm:text-3xl  font-semibold">A propos</h2><br>
                <div>
                    <p class=" md:px-5 max-md:mx-6 max-lg:mx-10 lg:px-12  max-sm:text-sm text-justify text-gray-600">
                        Notre plateforme web est bien plus qu'un simple espace en ligne. Grâce à notre service, vous pouvez accéder à une vaste liste de clients qui cherchent des produits de qualité. Nous mettons également à votre disposition un éventail de fournisseurs dans
                        tous les domaines de l'agriculture et de l'élevage. Vous avez de l'accès sur le calendrier agricole pour rester en phase avec les cycles naturels. Des recommandations saisonnières détaillées vous guideront dans la gestion optimale
                        de votre exploitaion, de la préparation du sol à la récolte.
                    </p><br>
                </div>
                <div class="grid grid-row-2 gap-y-20 max-sm:px-5 sm:px-16 py-8">
                    <div class=" max-md:flex max-md:flex-col lg:grid lg:grid-cols-2 h-fit border gap-3 w-fit ">
                        <div class="w-full ">
                            <img src="../../assets/pexels-cottonbro-studio-4911708.jpg" class="" alt="">
                        </div>
                        <div class="flex items-start justify-start  text-justify m-auto p-4">


                            <div class="">
                                <div class="">
                                    <h6 class="font-semibold">Produit élévage</h6>
                                    <p class="max-md:text-sm w-full text-justify text-gray-600">
                                        Explorez notre technique d'élevage qui marie habilement des pratiques modernes avec une tradition d'excellence. Des installations de pointe sont combinées à des méthodes éprouvées pour offrir des produits qui répondent aux normes les plus élevées de qualité
                                        et de durabilité. Les méthodes traditionnelles sont fusionnées avec des normes modernes pour créer des produits d'élevage qui incarnent une qualité supérieur et une attention méticuleuse à chaque détail... des normes
                                        modernes pour créer des produits d'élevage qui incarnent une qualité supérieur et une attention méticuleuse à chaque détail. <br><br>
                                        <!-- Découvrez notre approche consciente de l'élevage, où le respect des animaux et de l'environnement est fondamental. Chaque phase du processus reflète notre notre -->
                                        <!-- engagement envers la durabilité, depuis les conditions de vie confortables jusqu'à une alimentation équilibrée. Le résultat est une gamme exceptionnelle de produits d'élevage éthiques. <br><br> -->
                                    </p>
                                    <div class="flex justify-start items-center gap-x-4 text-blue-600  cursor-pointer">
                                        <div class="">En savoir plus</div>
                                        <div class="fas fa-arrow-right"></div>
                                    </div>
                                </div>

                            </div>

                          </div>
                       </div>
                   </div>

                   <div class="grid grid-row-2 gap-y-20 max-sm:px-5 sm:px-16 py-8">
                    <div class=" max-lg:flex max-lg:flex-col-reverse lg:grid lg:grid-cols-2 h-fit border gap-3 w-fit ">
                          <div class="">

                            <div class="">
                                <div class=" w-full text-left p-4">
                                    <h6 class="font-semibold">Produit agricole</h6>
                                    <p class="max-md:text-sm text-gray-600 w-full text-justify">
                                        Bienvenue dans notre parcours de production agricole, soigneusement élaboré pour garantir des produits de la plus haute qualité. Tout commence par la sélection attentive des semences, suivie d'une préparation méticuleuse du sol pour assurer des conditions
                                        de croissance optimale. Nous accordons une attention particulière à chaque étape, de la germination des cultures à la récolte finale, pour vous offrir des produits cultivés avec dévouement et expertise.<br>                                    Explorez avec nous le cycle complet de la production agricole, commençant par les semailles qui marquent le début de notre engagement envers la croissance durable. ...
                                        <!-- À mesure que les cultures grandissent, nous veillons à un entretien attentif, avec des pratiques agricoles respectueuses de l'environnement. La récolte, point culminant

                                   de notre processus, symbolise la concrétisation de nos efforts, offrant des produits de  première qualité. -->
                                    </p>
                                    <div class="flex justify-start items-center gap-x-4 text-blue-600  cursor-pointer">
                                        <div class="">En savoir plus</div>
                                        <div class="fas fa-arrow-right"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="">
                            <img src="../../assets/pexels-mark-stebnicki-2749165.jpg" class="" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <hr>
            <br>
            <div id="Pricing" class="overflow-x-auto">
                <h2 class="text-xl sm:text-3xl  font-semibold">Abonnement</h2>
                <Pricing></Pricing>
            </div>
            <br>
            <hr>
            <br>
            <div class="grid justify-center items-center w-screen" id="Contact">
                <div class="bg-white  overflow-hidden w-screen relative z-10">
                    <div class="flex flex-col justify-start w-screen items-center">
                        <div class="">
                            <h1 class="max-sm:text-xl sm:text-3xl  font-semibold">
                                Contact
                            </h1>
                        </div>
                        <div class="sm:flex max-sm:flex-col w-screen sm:px-4 sm:justify-between sm:items-start sm:gap-x-10">
                            <div class="sm:w-1/2 max-sm:w-full max-sm:px-4">
                                <div class="w-full mb-12 lg:mb-0">
                                    <h2 class="
                      text-dark
                      mb-6
                      uppercase
                      font-bold
                      text-xl
                      ">


                                    </h2>
                                    <p class="max-sm:text-sm text-justify text-body-color leading-relaxed mb-9 text-gray-600">
                                        N'hesitez pas à nous contacter pour toute question, demande d'information ou assistance. <br> Nous sommes là pour vous aider.
                                    </p>
                                </div>
                            </div>
                            <div class=" sm:w-1/2  max-sm:w-full">
                                <form class="w-full flex flex-col items-start justify-center px-4" @submit.prevent="envoyerMessage">
                                    <div class="mb-3 w-full">

                                        <input type="text" v-model="userName" disabled placeholder="Votre nom" class="
                        w-full hidden
                        placeholder:text-sm
                        py-1
                        px-1
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary rounded
                        " />
                                    </div>
                                    <div class="mb-3 w-full">
                                        <input type="email" v-model="userEmail" disabled placeholder="votre e-mail" class="
                        w-full hidden
                        placeholder:text-sm
                        py-1
                        px-1
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary rounded
                        " />
                                    </div>
                                    <div class="mb-3 w-full">
                                        <input type="text" v-model="userContact" disabled placeholder="Votre numéro" class="
                        w-full hidden
                        placeholder:text-sm
                        py-1
                        px-1
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary rounded
                        " />
                                    </div>
                                    <div class="mb-3 w-full">
                                        <textarea rows="6" v-model="message" placeholder="Votre message" class="
                        w-full
                        rounded
                        py-1 px-2
                        text-body-color text-base
                        border border-[f0f0f0]
                        resize-none
                        outline-none
                        placeholder:text-sm
                        focus-visible:shadow-none
                        focus:border-primary
                        "></textarea>

                                    </div>
                                    <div class="w-full">
                                        <button type="submit" class="
                            w-full
                            text-white
                            bg-blue-400 hover:bg-blue-500
                            px-1
                            border border-blue-400 hover:border-blue-500
                             py-1
                            transition
                            ">
                                                Envoyer
                                            </button>
                                    </div>
                                </form>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Header2 @modal_deconnection_show="data_modal_deconnnection" menu_mobile:menu_mobile @menu_mobile_show="data_menu_mobile"></Header2>
    <!--modal deconnection-->
    <div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal-connection" v-show="modal_deconnection">
        <div class="  box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center">
            <div class="flex justify-end items-center">
                <div class="mx-4">
                    <button @click="modal_deconnection = false, modal_back = false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div class="flex flex-col gap-3">
                <div>
                    <h5 class="font-bold text-xl">Deconnnection</h5>
                </div>
                <div class="flex gap-3">
                    <div>
                        <button class=" bg-gray-400 text-white p-1" @click="modal_deconnection = false">Annuler</button>
                    </div>
                    <div>
                        <button class="  bg-blue-400 hover:bg-blue-500 text-white p-1"><Router-Link to="/">Se
                                    deconnecter</Router-Link></button><br>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <!--modal menu mobile-->
    <div class=" fixed top-0 modal-mobile flex  w-screen h-screen bg-black bg-opacity-50 items-center justify-center" v-show="menu_mobile2">
        <div class="  fixed bg-white   w-[60vw] h-fit py-7 grid text-xs items-center justify-center sm:text-lg ">
            <div class="flex justify-end items-center">
                <div class=""><button class="fa fa-times fa-2x" @click="menu_mobile2 = false"></button></div>
            </div>
            <div class="p-6 flex flex-col gap-5 text-left">
                <div>
                    <RouterLink to="/Client/Accueil2" class="font-bold">
                        Accueil
                    </RouterLink>
                </div>
                <div>
                    <details>
                        <summary class="font-bold">Produit</summary>
                        <div class="flex flex-col p-1 gap-1 ml-5">
                            <div>
                                <RouterLink to="/Client/Produit">
                                    <h6>produit culture</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/ProduitAgricole">
                                    <h6>produit agricole</h6>
                                </RouterLink>
                            </div>
                        </div>
                    </details>
                </div>
                <div>
                    <router-link to="/Client/Calendrier" class="font-bold">Calendrier</router-link>
                </div>
                <div>
                    <details>
                        <summary class="font-bold">Marché</summary>
                        <div class="flex flex-col p-1 gap-1  ml-5">
                            <div>
                                <RouterLink to="/Client/Prix">
                                    <h6>Prix</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/Client">
                                    <h6>Client</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/Fournisseur">
                                    <h6>Fournisseur</h6>
                                </RouterLink>
                            </div>
                        </div>
                    </details>
                </div>
                <div>
                    <details>
                        <summary class="font-bold">Outils à la decision</summary>
                        <div class="flex flex-col p-1 gap-1  ml-5">
                            <div>
                                <RouterLink to="/Client/Courbe">
                                    <h6>Courbe</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/ProduitAgricole">
                                    <h6>produit agricole</h6>
                                </RouterLink>
                            </div>
                        </div>
                    </details>
                </div>
            </div>
        </div>
    </div>


    <div class="modal_ajout fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start" v-show="modalValidation">
    <div class="bg-white flex flex-col justify-center items-center shadow-sm shadow-gray-300 p-4 mt-20">
        <div class="w-full flex justify-end items-center">
            <div class="fa fa-times cursor-pointer" @click="modalValidation = false"></div>
        </div>
        <div class="fas fa-check rounded-full border-2 fa-2x text-green-400 border-green-400 p-2"></div>
        <div class="w-9/12">
            <p class="text-gray-500">Votre message a été bien envoyé !</p>
        </div>
    </div>
</div>

    <Footer></Footer>
</template>
<style sccoped>
    .clip-path-polygon {
        clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
    }
</style>
<script>

//import { RouterLink } from 'vue-router'
import apiUrl from '../../apiConfig';
import axios from 'axios'

import '@/components/style/header.css'
import '@/components/style/bodyAccueil.css'
import '@/components/style/all.css'
import Footer from '@/components/Footer.vue'
import Header2 from '@/components/Header2.vue'
//import Body2 from '@/components/Body2.vue'
export default {
    name: "Accueil2",
    components: {
        Header2,
        Footer,
        //Body2,


    },
    data() {
        return {

            typeAbonnement: '',
            modalValidation: false,
            iduser: '',
            message: '',
            typeAbo: '',
            userName: '',
            name: '',
            userEmail: '',
            email: '',
            userContact: '',
            contact: '',

            menu_mobile: false,
            modal_deconnection: false

        }
    },

    
    mounted() {

        // Accéder au nom de l'utilisateur à partir du computed
        /*const userName = this.userName;
        const userEmail = this.userEmail;
        const userId = this.userId;
        console.log('Nom de l\'utilisateur:', userName);
        console.log('Email de l\'utilisateur:', userEmail);
        console.log('Id de l\'utilisateur:', userId);*/

        // Pour récupérer depuis le localStorage
        this.iduser = localStorage.getItem('iduser');


        this.getTypeAbonnement();
        this.getUserName();
        this.getUserEmail();
        this.getUserContact();
    },

    methods: {
        data_menu_mobile(data) {
            this.menu_mobile2 = data
        },
        data_modal_deconnnection(data) {
            this.modal_deconnection = data
        },


        getTypeAbonnement() {
            axios.get(apiUrl + 'api/type_abonnement', { params: { iduser: this.iduser } })
            .then(response => {
                this.typeAbo = response.data[0].type;

                localStorage.setItem('typeAbonnement', this.typeAbo);

                this.typeAbonnement = localStorage.getItem('typeAbonnement');

            }).catch(error => {
                console.log(error)
            })

        },

        getUserName() {
            axios.get(apiUrl + 'api/user_name', { params: { iduser: this.iduser } })
            .then(response => {
                this.name = response.data[0].name;

                localStorage.setItem('nomUser', this.name);

                this.userName = localStorage.getItem('nomUser');

            }).catch(error => {
                console.log(error)
            })
        },
        getUserEmail() {
            axios.get(apiUrl + 'api/user_email', { params: { iduser: this.iduser } })
            .then(response => {
                this.email = response.data[0].email;

                localStorage.setItem('emailUser', this.email);

                this.userEmail = localStorage.getItem('emailUser');

            }).catch(error => {
                console.log(error)
            })
        },
        getUserContact() {
            axios.get(apiUrl + 'api/user_contact', { params: { iduser: this.iduser } })
            .then(response => {
                this.contact = response.data[0].contact;

                localStorage.setItem('contactUser', this.contact);

                this.userContact = localStorage.getItem('contactUser');

            }).catch(error => {
                console.log(error)
            })
        },

        envoyerMessage() {
            const formData = new FormData();
            formData.append('nom', this.userName);
            formData.append('email', this.userEmail);
            formData.append('numero', this.userContact);
            formData.append('message', this.message);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/envoyer_message', formData)
            .then(response => {
                this.userName='', this.userEmail='', this.userContact='', this.message='';
                this.modalValidation=true;
                console.log('Message envoyer avec succès');
            })
            .catch(error => {
                console.log(error)
            });
        },
    }

    }
</script>