<template>

    <div>
        <br><br><br>
    <div>
        <div class="bg-white h-fit  flex  items-center justify-between max-sm:hidden ">
        <div class="flex justify-start items-center max-md:mx-4 lg:mx-2">
            <div class="flex justify-center items-center gap-x-6 max-md:mx-3 w-fit">
                <div class="fa fa-home px-1 cursor-pointer" @click="$router.push('/Client/Accueil2')"></div>
                <div class="fas fa-caret-right px-1 text-gray-500"></div>
                <div class="w-fit "><span>Produit</span></div>
                <div class="fas fa-caret-right px-1 text-gray-500"></div>
                <div class="w-fit "><span @click="$router.push('/Client/ProduitElevage')" class="cursor-pointer">Elevage</span></div>
            </div>
        </div>
            <div class="flex max-sm:flex-col sm:flex-wrap justify-end items-center gap-4 w-full">
                <div class="border flex">
                    <div class="border-r"><input type="text " placeholder="Origine..." class="focus:outline-none placeholder:text-sm py-1 mx-2" v-model="inpOrigine"></div>
                    <div><input type="text" placeholder="Produit..." class="focus:outline-none py-1 placeholder:text-sm mx-2" v-model="inpNom"></div>
                </div>
                <div>
                    <button class="px-4 py-1  bg-blue-400 hover:bg-blue-500 text-white" @click="rechercheElevage()">Rechercher</button>
                </div>
                <div class="flex max-md:mx-3 justify-end items-center">
                    <div class="p-3" v-show="content_mode==true">
                        <button @click="change_content_mode(false)" class="fas fa-table-cells fa-2x text-gray-600"></button>
                    </div>
                    <div class="p-3" v-show="content_mode==false">
                        <button @click="change_content_mode(true)" class="fas fa-table-list fa-2x text-gray-600"></button>
                    </div>
                </div> 
            </div>
        </div>
        <div class="bg-white h-fit  flex flex-col items-start justify-center sm:hidden">
        <div class="flex justify-start items-center ">
            <div class="flex justify-center items-center gap-x-6 mx-3 w-fit">
                <div class="fa fa-home pr-1 cursor-pointer" @click="$router.push('/Client/Accueil2')"></div>
                <div class="fas fa-caret-right pr-1 text-gray-500"></div>
                <div class="w-fit "><span>Produit</span></div>
                <div class="fas fa-caret-right pr-1 text-gray-500"></div>
                <div class="w-fit "><span @click="$router.push('/Client/Produit')" class="cursor-pointer">Culture</span></div>
            </div>
        </div>
        <!--filtre pour sm -->
            <div class="flex max-sm:hidden flex-wrap justify-end items-center gap-4 w-fit">
                <div class="border flex">
                    <div class="border-r"><input type="text " placeholder="Origine..." class="focus:outline-none placeholder:text-sm py-1 mx-2" v-model="inpOrigine"></div>
                    <div><input type="text" placeholder="Produit..." class="focus:outline-none py-1 placeholder:text-sm mx-2" v-model="inpNom"></div>
                </div>
                <div>
                    <button class="px-4 py-1  bg-blue-400 hover:bg-blue-500 text-white" @click="rechercheElevage()">Rechercher</button>
                </div>
                
                <div class="flex sm:mx-3">
                    <div class="p-3" v-show="content_mode==true">
                        <button @click="change_content_mode(false)" class="fas fa-table-cells fa-2x"></button>
                    </div>
                    <div class="p-3" v-show="content_mode==false">
                        <button @click="change_content_mode(true)" class="fas fa-table-list fa-2x"></button>
                    </div>
                </div> 
            </div>
            <br>
            <div class="flex sm:hidden flex-col justify-end items-center gap-4 w-screen px-2">
                <div class="border flex w-full">
                    <div class="border-r w-1/2"><input type="text " placeholder="Origine..." class="focus:outline-none placeholder:text-sm py-1 mx-2" v-model="inpOrigine"></div>
                    <div class="w-1/2"><input type="text" placeholder="Produit..." class="focus:outline-none py-1 placeholder:text-sm mx-2" v-model="inpNom"></div>
                </div>
                <div class="flex  justify-end items-center gap-3 w-full">
                    <div>
                        <button class="px-4 py-1  bg-blue-400 hover:bg-blue-500 text-white" @click="rechercheCulture()">Rechercher</button>
                    </div>
                    <div class="flex sm:mx-3 justify-end items-center">
                        <div class="sm:p-3" v-show="content_mode==true">
                            <button @click="change_content_mode(false)" class="fas fa-table-cells fa-2x text-gray-600"></button>
                        </div>
                        <div class="sm:p-3" v-show="content_mode==false">
                            <button @click="change_content_mode(true)" class="fas fa-table-list fa-2x text-gray-600"></button>
                        </div>
                    </div> 
                </div>
            <br>
            </div>
        </div>
    
    </div>
    
       
<div class="w-screen bg-slate-50 p-2">
                <div class="grid max-sm:grid-cols-2   gap-2  md:grid-cols-3 lg:grid-cols-4 "  v-if="content_mode" >
                    <div v-for="elevage in elevages" :key="elevage.idelevage" class="flex flex-col bg-white justify-between items-center shadow-sm shadow-slate-300 p-2"  >
                    <div class="flex justify-center items-center w-full h-60">
                            <img :src="getImageUrl(elevage.image)" class="w-full h-full object-cover" alt="photo">
                    </div>
                    <div class="w-full py-1">
                        <div class="flex justify-between items-center">
                            <div class="font-semibold">
                                {{ elevage.nom }}
                            </div>
                            <div class="text-gray-600">
                                {{ elevage.origine }}
                            </div>
                        </div>
                        <div class="w-full">
                            <div class="flex justify-center  items-center w-full">
                                <div class="w-full ">
                                    <router-link :to="{ path: '/Client/DetailsProdElevage/' + elevage.idelevage }">
                                        <button type="button" class=" py-1 border-2  border-blue-400 w-full  hover:border-blue-500 hover:bg-blue-500   hover:text-white" >Details</button> 
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>               
                </div>
                <div class="w-full md:px-2 overflow-x-auto" v-else>
                    
                    <table class="w-full ">
                        <thead class="text-left">
                            
                            <th class="px-6 py-2 border-b border-gray-200 bg-gray-300  hidden font-semibold text-gray-600 " >Id</th>
                                <th class="px-6 py-2 border-b border-gray-200 bg-gray-300  font-semibold text-gray-600 ">Nom</th>
                                <th class="px-6 py-2 border-b border-gray-200 bg-gray-300  font-semibold text-gray-600 ">Origine</th>
                                <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 font-semibold text-gray-600 ">Catégorie</th>
                                <th class="px-6 py-2 border-b border-gray-200 bg-gray-300  font-semibold text-gray-600 "></th>
                            
                            
                        </thead>
                        <tbody class="text-gray-600 max-sm:text-sm text-left">
                            
                            <tr v-for="elevage in elevages" :key="elevage.idelevage" class="hover:bg-gray-100 cursor-pointer" >
                                <td class="px-3 py-2 border-b border-gray-200 hidden">{{ elevage.idelevage }}</td>
                                <td class="px-3 py-2 border-b border-gray-200">{{ elevage.nom }}</td>
                                <td class="px-3 py-2 border-b border-gray-200">{{ elevage.origine }}</td>
                                <td class="px-3 py-2 border-b border-gray-200">{{ elevage.categorie }}</td>
                                <td class="px-3 py-2 border-b border-gray-200">
                                    <router-link :to="{ path: '/Client/DetailsProdElevage/' + elevage.idelevage }">
                                    <button class="fas fa-eye"></button>
                                    </router-link>
                                </td>
                            </tr>
                            
                        </tbody>
                </table>
               
            </div>
</div>
    <Footer></Footer>
    </div>
    <Header2 @modal_deconnection_show="data_modal_deconnnection"  @menu_mobile_show="data_menu_mobile"></Header2>

    <!--modal deconnection-->
    <div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal-connection" v-show="modal_deconnection">
        <div class="  box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center" >
            <div class="flex justify-end items-center">  
                <div class="mx-4">
                    <button @click="modal_deconnection=false,modal_back=false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div class="flex flex-col gap-3">
                <div>
                    <h5 class="font-bold text-xl">Deconnnection</h5>
                </div>
                <div class="flex gap-3">
                    <div>
                        <button class="btn  bg-gray-400 text-white p-1" @click="modal_deconnection=false">Annuler</button>
                    </div>
                    <div>
                        <button  class="btn  bg-blue-500 text-white p-1"><Router-Link to="/">Se deconnecter</Router-Link></button><br>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <!--modal Details-->
    <div class=" modal_ajout w-screen h-screen bg-black bg-opacity-50  flex justify-center items-center" v-show="modal_details" >
        <div class="flex w-[90vw] h-[90vh] bg-white flex-col  p-8">
            <div class="w-full flex justify-end items-center ">
            <div class="m-5">
                <button class="fas fa-times fa-2x" @click="modal_details=false, init()"></button>
            </div>
        </div>
        <div>
            <p><span class="font-bold"></span></p>
        </div>
    <div class="m-6 ">
        <input v-model="idelevage1">
        <div class=" bg-white">
            
            <details>
                <summary class="font-bold text-2xl">Karazany</summary>
                <div class="p-2 bg-slate-200 ">
                    <h4>Anarana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="nom2" disabled></textarea>
                    <h4>Mombamomba :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="description1" disabled></textarea>
                </div>
            </details>
            <details>
                <summary class="font-bold text-xl">Fikarakarana</summary>
                <div class="p-2 bg-slate-200 ">
                    <h4>Tanjona :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="tanjona1" disabled></textarea>
                    <h4>Dingana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="dingana1" disabled></textarea>
                </div>
            </details>
           <details>
            <summary class="font-bold text-xl">Sakafo</summary>
                <div class="p-2 bg-slate-200 ">
                    <h4>Fotoana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fotoana1" disabled></textarea>
                    <h4>Fomba atao :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fombaAtao1" disabled></textarea>
                    <h4>Fatran'ny sakafo :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fatrany1" disabled></textarea>
                </div>
           </details>
            <details>
                <summary class="font-bold text-xl">Fonenany</summary>
                <div class="p-2 bg-slate-200 ">
                    <h4>Mombamomban'ny fonenany :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fotoana1" disabled></textarea>
                    <h4>Refiny :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fombaAtao1" disabled></textarea>
                    <h4>Fitaovana ilaina :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fatrany1" disabled></textarea>
                </div>
            </details>
        </div>
    </div>

        </div>
</div>
    <!--modal menu mobile-->
    <div  class=" fixed top-0 modal-mobile flex  w-screen h-screen bg-black bg-opacity-50 items-center justify-center" v-show="menu_mobile2">
        <div class="  fixed bg-white   w-[60vw] h-fit py-7 grid text-xs items-center justify-center sm:text-lg ">
                <div class="flex justify-end items-center">
                    <div class=""><button class="fa fa-times fa-2x" @click="menu_mobile2=false"></button></div>
                </div>
                <div class="p-6 flex flex-col gap-5 text-left">
                    <div>
                        <RouterLink to="/Client/Accueil2" class="font-bold">
                            Accueil
                        </RouterLink>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Produit</summary>
                            <div class="flex flex-col p-1 gap-1 ml-5">
                                <div><RouterLink to="/Client/Produit"><h6>produit culture</h6></RouterLink></div>
                                <div><RouterLink to="/Client/ProduitElevage"><h6>produit agricole</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    <div>
                        <router-link to="/Client/Calendrier" class="font-bold">Calendrier</router-link>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Marché</summary>
                            <div class="flex flex-col p-1 gap-1  ml-5">
                                <div><RouterLink to="/Client/Prix"><h6>Prix</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Client"><h6>Client</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Fournisseur"><h6>Fournisseur</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Outils à la decision</summary>
                            <div class="flex flex-col p-1 gap-1  ml-5">
                                <div><RouterLink to="/Client/Courbe"><h6>Courbe</h6></RouterLink></div>
                                <div><RouterLink to="/Client/ProduitAgricole"><h6>produit agricole</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    
                </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import apiUrl from '../../apiConfig';
// eslint-disable-next-line no-unused-vars
import { RouterLink } from 'vue-router'
import Footer from '@/components/Footer.vue'
import Header2 from '@/components/Header2.vue'
//import Triage from '@/components/Triage.vue'
import Pagination from '@/components/Pagination.vue'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';

DataTable.use(DataTablesCore);
 
// eslint-disable-next-line no-unused-vars
const data = [
  [1, 2],
  [3, 4],
];
    export default {
        name:'ProduitElevage',
        components:{
            Footer,
            Header2,
            
            // eslint-disable-next-line vue/no-unused-components
            Pagination
        },
        data(){
            return{ 
                inpNom:'', inpOrigine:'',
                idelevage1:'',
                elevages:[], nom2:'', description1:'',
                tanjona1:'', dingana1:'',
                fotoana1:'', fombaAtao1:'', fatrany1:'',
                mombamomba1:'', refiny1:'', fitaovana1:'',

                modal_details:false,
                content_mode_table:true,
                content_mode_modal:false,
                idelevage: null,
                sections: [
          { id: 'Service', title: 'Service', isActive: false },
          { id: 'Propos', title: 'Propos', isActive: false },
          { id: 'Pricing', title: 'Pricing', isActive: false },
          { id: 'Contact', title: 'Contact', isActive: false },
          // Add more sections as neededwaa
        ],menu_mobile2:false,
        modal_deconnection:false,
        content_mode:Boolean,
            }
        },
        
        mounted(){
            this.getElevages();
            this.getImageUrl();

            this.d()
             console.log(localStorage.getItem('content_mode'))
        },
        // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
        beforeDestroy() {
            localStorage.setItem('content_mode',String(this.content_mode))
        },
        
        methods:{

            getImageUrl(image) {
            // Utilisez la fonction asset() de Laravel pour générer l'URL de l'image
            return apiUrl + `images/${image}`;
            },

            init(){
                this.nom2='', this.description1='', this.tanjona1='', this.dingana1='', 
                this.fotoana1='', this.fombaAtao1='', this.fatrany1='', 
                this.mombamomba1='', this.refiny1='', this.fitaovana1=''
                
            },

            getElevages(){
            axios.get(apiUrl + 'api/affichage_elevage').then(response => {
              this.elevages = response.data
              // eslint-disable-next-line no-undef
              
            }).catch(error =>{
              console.log(error)
              this.fournisseurs=[]
            })
            },

            rechercheElevage(){
                axios.get(apiUrl + 'api/recherche_elevage_front', {params: {inpNom: this.inpNom, inpOrigine:this.inpOrigine}}).then(response => {
                    this.elevages = response.data
                    }).catch(error =>{
                    console.log(error)
                    })
            },

            detail_karazany(){
                // eslint-disable-next-line no-unused-vars
                axios.get(apiUrl + 'api/affichage_karazany',{params:{idelevage3: this.idelevage1}}).then(response => {
                    this.nom2 = response.data[0].nom;
                    this.description1 = response.data[0].description;
                })
                .catch(error => {(
                    console.log(error)
                )})
            },

            detail_fikarakarana(){
                // eslint-disable-next-line no-unused-vars
                axios.get(apiUrl + 'api/affichage_fikarakarana',{params:{idelevage3: this.idelevage1}}).then(response => {
                    this.tanjona1 = response.data[0].tanjona;
                    this.dingana1 = response.data[0].dingana;
                })
                .catch(error => {(
                    console.log(error)
                )})
            },

            detail_sakafo(){
                // eslint-disable-next-line no-unused-vars
                axios.get(apiUrl + 'api/affichage_sakafo',{params:{idelevage3: this.idelevage1}}).then(response => {
                    this.fotoana1 = response.data[0].fotoana;
                    this.fombaAtao1 = response.data[0].fombaAtao;
                    this.fatrany1 = response.data[0].fatrany;
                })
                .catch(error => {(
                    console.log(error)
                )})
            },

            detail_fonenany(){
                // eslint-disable-next-line no-unused-vars
                axios.get(apiUrl + 'api/affichage_fonenany',{params:{idelevage3: this.idelevage}}).then(response => {
                    this.mombamomba1 = response.data[0].mombamomba;
                    this.refiny1 = response.data[0].refiny;
                    this.fitaovana1 = response.data[0].fitaovana;
                })
                .catch(error => {(
                    console.log(error)
                )})
            },


            d(){
                
                this.content_mode=JSON.parse(localStorage.getItem('content_mode'))
            
        },
        change_content_mode:function(data){
            this.content_mode=data
            localStorage.setItem('content_mode',String(this.content_mode))
        },modal_detail_show:function(){
                if(this.modal_detail){
                    this.modal_detail=false
                    this.modal_back=false
                } else {
                    this.modal_detail=true
                    this.modal_back=true
                }
            },data_menu_mobile(data){
                this.menu_mobile2=data
            },
            data_modal_deconnnection(data){
                this.modal_deconnection=data
            },

            envoyerId(){
                this.$router.currentRoute.value.params = { param1: this.idelevage};
                this.$router.push({ name: 'DetailsProdElevage' });
            }

        }
    }
</script>