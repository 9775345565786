<template>


<div>
    <br><br><br>
    <!-- filtre pour md+  -->
    <div class="bg-white h-fit  flex  items-center justify-between max-sm:hidden ">
        <div class="flex justify-start items-center max-md:mx-4 lg:mx-2">
            <div class="flex justify-center items-center gap-x-6 mx-3 w-fit">
                <div class="fa fa-home px-1 cursor-pointer" @click="$router.push('/Client/Accueil2')"></div>
                <div class="fas fa-caret-right px-1 text-gray-500"></div>
                <div class="w-fit "><span>Produit</span></div>
                <div class="fas fa-caret-right px-1 text-gray-500"></div>
                <div class="w-fit "><span @click="$router.push('/Client/Produit')" class="cursor-pointer">Culture</span></div>
            </div>
        </div>
            <div class="flex  justify-end items-center gap-4 w-fit">
                <div class="border flex">
                    <div class="border-r"><input type="text " placeholder="Origine..." class="focus:outline-none placeholder:text-sm py-1 mx-2" v-model="inpOrigine"></div>
                    <div><input type="text" placeholder="Produit..." class="focus:outline-none py-1 placeholder:text-sm mx-2" v-model="inpNom"></div>
                </div>
                <div>
                    <button class="px-4 py-1  bg-blue-400 hover:bg-blue-500 text-white" @click="rechercheCulture()">Rechercher</button>
                </div>
                
                <div class="flex max-md:mx-3">
                    <div class="p-3" v-show="content_mode==true">
                        <button @click="change_content_mode(false)" class="fas fa-table-cells fa-2x text-gray-600"></button>
                    </div>
                    <div class="p-3" v-show="content_mode==false">
                        <button @click="change_content_mode(true)" class="fas fa-table-list fa-2x text-gray-600"></button>
                    </div>
                </div> 

            </div>
        </div>
        <div class="bg-white h-fit  flex flex-col items-start justify-center sm:hidden">
        <div class="flex justify-start items-center ">
            <div class="flex justify-center items-center gap-x-6 mx-3 w-fit">
                <div class="fa fa-home pr-1 cursor-pointer" @click="$router.push('/Client/Accueil2')"></div>
                <div class="fas fa-caret-right pr-1 text-gray-500"></div>
                <div class="w-fit "><span>Produit</span></div>
                <div class="fas fa-caret-right pr-1 text-gray-500"></div>
                <div class="w-fit "><span @click="$router.push('/Client/Produit')" class="cursor-pointer">Culture</span></div>
            </div>
        </div>
        <!--filtre pour sm -->
            <div class="flex max-sm:hidden flex-wrap justify-end items-center gap-4 w-fit">
                <div class="border flex">
                    <div class="border-r"><input type="text " placeholder="Origine..." class="focus:outline-none placeholder:text-sm py-1 mx-2" v-model="inpOrigine"></div>
                    <div><input type="text" placeholder="Produit..." class="focus:outline-none py-1 placeholder:text-sm mx-2" v-model="inpNom"></div>
                </div>
                <div>
                    <button class="px-4 py-1  bg-blue-400 hover:bg-blue-500 text-white" @click="rechercheCulture()">Rechercher</button>
                </div>
                
                <div class="flex sm:mx-3">
                    <div class="p-3" v-show="content_mode==true">
                        <button @click="change_content_mode(false)" class="fas fa-table-cells fa-2x"></button>
                    </div>
                    <div class="p-3" v-show="content_mode==false">
                        <button @click="change_content_mode(true)" class="fas fa-table-list fa-2x"></button>
                    </div>
                </div> 

            </div>
            <br>
            <div class="flex sm:hidden flex-col justify-end items-center gap-4 w-screen px-2">
                <div class="border flex w-full">
                    <div class="border-r w-1/2"><input type="text " placeholder="Origine..." class="focus:outline-none placeholder:text-sm py-1 mx-2" v-model="inpOrigine"></div>
                    <div class="w-1/2"><input type="text" placeholder="Produit..." class="focus:outline-none py-1 placeholder:text-sm mx-2" v-model="inpNom"></div>
                </div>
                <div class="flex  justify-end items-center gap-3 w-full">
                    <div>
                        <button class="px-4 py-1  bg-blue-400 hover:bg-blue-500 text-white" @click="rechercheCulture()">Rechercher</button>
                    </div>
                
                    <div class="flex sm:mx-3 justify-end items-center">
                        <div class="sm:p-3" v-show="content_mode==true">
                            <button @click="change_content_mode(false)" class="fas fa-table-cells fa-2x"></button>
                        </div>
                        <div class="sm:p-3" v-show="content_mode==false">
                            <button @click="change_content_mode(true)" class="fas fa-table-list fa-2x"></button>
                        </div>
                    </div> 
                </div>
<br>
            </div>
        </div>
    
<div class="w-screen bg-slate-50 p-2">
            
                <div  class=" grid grid-cols-2 max-md:gap-1  md:gap-4 justify-center items-start  md:grid-cols-3 lg:grid-cols-4 w-full md:px-4"  v-if="content_mode" >
                    <div v-for="culture in cultures" :key="culture.idculture" class="flex flex-col bg-white  p-2 shadow-sm shadow-slate-300 ">
                        <div class="flex justify-center items-center w-full h-60 ">
                            <img :src="getImageUrl(culture.image)" class="w-full h-full object-cover" alt="photo" @click="details_image">
                        </div><br>
                        <div class="flex justify-center items-center">
                           
                            <div class="flex justify-between items-center md:mx-2 w-full gap-x-2">
                                <div><div class="font-semibold"> {{ culture.nom }}</div></div>
                                <div class="flex justify-end items-center">
                                     <div class="text-gray-600 text-center my-auto">
                                        {{ culture.origine }}
                                     </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="text-left py-2">
                            <p> {{ culture.categorie }}</p>
                        </div> -->
                        <div class="flex w-full justify-center items-center">
                            <div class="w-full">
                                <router-link :to="{ path: '/Client/DetailsProd/' + culture.idculture }"> <!-- Remplacez 123 par la valeur réelle de votre ID -->
                                    <button class="max-md:px-1 md:px-2 py-1 w-full border-blue-400 border-2 hover:bg-blue-500 hover:border-blue-500 hover:text-white">
                                        Details
                                    </button>
                                </router-link>
                        </div><br>
                    </div>
                    </div>
                    
                    
                    
                </div>

            <div class="max-sm:px-2 max-sm:py-2 md:px-4 w-full max-sm:overflow-x-scroll md:overflow-x-auto" v-else>
                
                    <table class="min-w-full ">
                        <thead class="">
                            
                                <th class="px-6 sm:py-2 border-b border-gray-200 bg-gray-300 text-center max-md:hidden font-semibold text-gray-600 hidden" >Id</th>
                                <th class="px-6 sm:py-2 border-b border-gray-200 bg-gray-300 text-center  font-semibold text-gray-600 ">Nom</th>
                                <th class="px-6 sm:py-2 border-b border-gray-200 bg-gray-300 text-center  font-semibold text-gray-600 ">Origine</th>
                                <th class="px-6 sm:py-2 border-b border-gray-200 bg-gray-300 text-center  font-semibold text-gray-600 ">Catégorie</th>
                                <th class="px-6 sm:py-2 border-b border-gray-200 bg-gray-300 text-center  font-semibold text-gray-600 "></th>
                            
                            
                        </thead>
                        <tbody class="text-gray-600 max-sm:text-sm">
                            <tr v-for="culture in cultures" :key="culture.idculture" class="hover:bg-gray-100 cursor-pointer " @click="idculture1 = culture.idculture, detail_karazany(), detail_ankapobeny(),
                            detail_fahavalo(), detail_fambolena(), detail_fitsaboana()">
                                <td class="px-3 py-2 border-b border-gray-200 hidden">{{ culture.idculture }}</td>
                                <td class="px-3 py-2 border-b border-gray-200">{{ culture.nom }}</td>
                                <td class="px-3 py-2 border-b border-gray-200">{{ culture.origine }}</td>
                                <td class="px-3 py-2 border-b border-gray-200">{{ culture.categorie }}</td>
                                <td class="px-3 py-2 border-b border-gray-200">
                                    <button class="fas fa-eye" @click="$router.push('/Client/DetailsProd/' + culture.idculture)"></button>
                                </td>
                            </tr>
                            
                        </tbody>
                </table>
               
            </div>

</div>
   
    <Footer></Footer>
</div>
    <Header2 @modal_deconnection_show="data_modal_deconnnection"  @menu_mobile_show="data_menu_mobile"></Header2>
  <div class="w-screen h-screen bg-slate-400 modal_fixed_5" v-show="modal_details_image">
        <div class="w-[80vh] h-fit ">
            <img src="./../../assets/pexels-mark-stebnicki-2749165.jpg" class="" alt="photo">
        </div>
    </div>
    <!--modal deconnection-->
  
    <div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal-connection" v-show="modal_deconnection">
        <div class="  box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center" >
            <div class="flex justify-end items-center">  
                <div class="mx-4">
                    <button @click="modal_deconnection=false,modal_back=false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div>
                <div>
                    <h5>deconnnection</h5>
                </div>
                <div>
                    <form class="form form-check">
                        <button @click.prevent="" class="btn  bg-blue-600 text-white p-1"><Router-Link to="/Client/Accueil2">Se deconnecter</Router-Link></button><br>
                    </form>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <!--modal menu mobile-->
    <div  class=" fixed top-0 modal-mobile flex  w-screen h-screen bg-black bg-opacity-50 items-center justify-center" v-show="menu_mobile2">
        <div class="  fixed bg-white   w-[60vw] h-fit py-7 grid text-xs items-center justify-center sm:text-lg ">
                <div class="flex justify-end items-center">
                    <div class=""><button class="fa fa-times fa-2x" @click="menu_mobile2=false"></button></div>
                </div>
                <div class="p-6 flex flex-col gap-5 text-left">
                    <div>
                        <RouterLink to="/Client/Accueil2" class="font-bold">
                            Accueil
                        </RouterLink>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Produit</summary>
                            <div class="flex flex-col p-1 gap-1 ml-5">
                                <div><RouterLink to="/Client/Produit"><h6>produit culture</h6></RouterLink></div>
                                <div><RouterLink to="/Client/ProduitElevage"><h6>produit agricole</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    <div>
                        <router-link to="/Client/Calendrier" class="font-bold">Calendrier</router-link>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Marché</summary>
                            <div class="flex flex-col p-1 gap-1  ml-5">
                                <div><RouterLink to="/Client/Prix"><h6>Prix</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Client"><h6>Client</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Fournisseur"><h6>Fournisseur</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Outils à la decision</summary>
                            <div class="flex flex-col p-1 gap-1  ml-5">
                                <div><RouterLink to="/Client/Courbe"><h6>Courbe</h6></RouterLink></div>
                                <div><RouterLink to="/Client/ProduitAgricole"><h6>produit agricole</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    
                </div>
        </div>
    </div>
    <!--modal details-->
    <div class=" modal_ajout w-screen h-screen bg-black bg-opacity-50  flex justify-center items-center" v-show="modal_details" >
        <div class="flex w-[90vw] h-[90vh] bg-white flex-col  p-8">
            <div class="w-full flex justify-end items-center ">
            <div class="m-5">
                <button class="fas fa-times fa-2x" @click="modal_details=false, init()"></button>
            </div>
        </div>
        <div>
            <p><span class="font-bold"></span></p>
        </div>
    <div class="m-6 ">
        <input v-model="idculture1">
        <div class=" bg-white">
            <details>
                <summary class="font-bold text-2xl">Ankapobeny</summary>
                <div class="p-2 bg-slate-200 ">
                    <h4>Toe-tany :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="toeTany1" disabled></textarea>
                    <h4>Fotoam-pambolena :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fotoanaFambolena1" disabled></textarea>
                    <h4>Toe-trandro :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="toeTrandro1" disabled></textarea>
                </div>
            </details>
            
            <details>
                <summary class="font-bold text-2xl">Karazany</summary>
                <div class="p-2 bg-slate-200 ">
                    <h4>Anarana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="nom2" disabled></textarea>
                    <h4>Mombamomba :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="description1" disabled></textarea>
                </div>
            </details>
            <details>
                <summary class="font-bold text-2xl ">Fambolena</summary>
                <div class="p-2 bg-slate-200 ">
                    <h4>Fikarakarana ny tany :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fikarakaranaTany1" disabled></textarea>
                    <h4>Famafazana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="famafazana1" disabled></textarea>
                    <h4>Fikarakarana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fikarakarana1" disabled></textarea>
                    <h4>Famindrana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="famindrana1" disabled></textarea>
                    <h4>Fijinjana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fijinjana1" disabled></textarea>
                    <h4>Fiotazana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fiotazana1" disabled></textarea>
                    <h4>Fanetsana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fanetsana1" disabled></textarea>
                    <h4>Fitehirizana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fitehirizana1" disabled></textarea>
                    <h4>Fatran'ny zezika :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fatraZezika1" disabled></textarea>
                </div>
            </details>
           <details>
            <summary class="font-bold text-2xl ">Fahavalo</summary>
            <div class="p-2 bg-slate-200 ">
                <h4>Anarana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="anarana1" disabled></textarea>
                <h4>Mombamomba :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="mombamomba1" disabled></textarea>
                <h4>Asany :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="asany1" disabled></textarea>
            </div>
           </details>
            <details>
                <summary class="font-bold text-2xl ">Fitsaboana</summary>
                <div class="p-2 bg-slate-200 ">
                    <h4>Anaran'aretina :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="anaranAretina1" disabled></textarea>
                    <h4>Vaha olana :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="vahaOlana1" disabled></textarea>
                    <h4>Fomba fisehony² :</h4> <textarea name="" id="i" cols="45" rows="3" v-model="fombaFisehony1" disabled></textarea>
                </div>
            </details>
        </div>
    </div>

        </div>
</div>
<div class="modal_ajout bg-black bg-opacity-50 justify-center items-center h-screen w-screen" v-show="modal_menu">
        <div class="bg-white  p-5 ">
            <div class="flex flex-col gap-5">
                <div class="flex justify-end items-center" >
                    <div class="fas fa-times fa-2x" @click="modal_menu=false"></div>
                </div>
            <div class="bg-green-700 p-1  text-white mx-9 flex" @click="generer_pdf()">
                Generer en pdf
            </div>
            <div class=" page-1  mx-9" :style="{border:Ankapobeny?'3px solid #009100':'white'}"  @click="p_Ankapobeny()"><h5>Ankapobeny</h5></div>
            <div class=" page-2  mx-9" :style="{border:Karazany?'3px solid #009100':'white'}" @click="p_Karazany()"><h5>Karazany</h5></div>
            <div class=" page-3  mx-9" :style="{border:Fambolena?'3px solid #009100':'white'}"  @click="p_Fambolena()"><h5>Fambolena</h5></div>
            <div class=" page-4  mx-9" :style="{border:Fahavalo?'3px solid #009100':'white'}"  @click="p_Fahavalo()"><h5>Fahavalo</h5></div>
            <div class=" page-5  mx-9" :style="{border:Fitsaboana?'3px solid #009100':'white'}"  @click="p_Fitsaboana()"><h5>Fitsaboana</h5></div>
    </div>
        </div>
    </div>
    
</template>
<script>
import axios from 'axios';
import apiUrl from '../../apiConfig';
//import { RouterLink } from 'vue-router'
import Footer from '@/components/Footer.vue'
import Header2 from '@/components/Header2.vue'
//import Triage from '@/components/Triage.vue'
import Pagination from '@/components/Pagination.vue'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import jsPDF from 'jspdf';
DataTable.use(DataTablesCore);
 
// eslint-disable-next-line no-unused-vars
const data = [
  [1, 2],
  [3, 4],
];
    export default {
        name:'Produit',
        components:{
            Footer,
            Header2,
    
            // eslint-disable-next-line vue/no-unused-components
            Pagination
        },
        data(){
            return{

                modal_details_image:false,

                inpNom:'', inpCat:'',

                inpOrigine:'',

                idculture1:'',
                cultures:[], nom2:'', description1:'',
                toeTany1:'', fotoanaFambolena1:'',toeTrandro1:'', 
                fikarakaranaTany1:'', famafazana1:'', fikarakarana1:'', famindrana1:'', fijinjana1:'', fiotazana1:'', fanetsana1:'', fitehirizana1:'', fatraZezika1:'',
                anarana1:'', mombamomba1:'', asany1:'',
                anaranAretina1:'',vahaOlana1:'',fombaFisehony1:'',

                modal_menu:false,
                Ankapobeny:true,
                Karazany:false,
                Fambolena:false,
                Fahavalo:false,
                Fitsaboana:false,
                modal_details:false,
                
                
                sections: [
          { id: 'Service', title: 'Service', isActive: false },
          { id: 'Propos', title: 'Propos', isActive: false },
          { id: 'Pricing', title: 'Pricing', isActive: false },
          { id: 'Contact', title: 'Contact', isActive: false },
          // Add more sections as neededwaa
        ],menu_mobile2:false,
        modal_deconnection:false,
        content_mode:false,
            }
        },mounted(){
            
            this.getImageUrl();
            this.getCultures();

            this.d()
             //if(localStorage.getItem('content_mode')!==null){
             //   console.log('null')
             //} else {
             //   console.log('ok')
             //}
        },
        
        // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
        beforeDestroy() {
           // localStorage.setItem('content_mode',String(this.content_mode))
        },
        
        methods:{
            

            getImageUrl(image) {
            // Utilisez la fonction asset() de Laravel pour générer l'URL de l'image
            return apiUrl + `images/${image}`;
            },

            // eslint-disable-next-line vue/no-dupe-keys
            modal_details_image(){
                this.details_image=true
            },
            init(){
                this.nom2='', this.description1='', this.toeTany1='', this.fotoanaFambolena1='', this.toeTrandro1='',
                this.fikarakaranaTany1='', this.famafazana1='', this.fikarakarana1='', this.famindrana1='', this.fijinjana1='', this.fiotazana1='', 
                this.fanetsana1='', this.fitehirizana1='', this.fatraZezika1='', 
                this.anarana1='', this.mombamomba1='', this.asany1='', 
                this.anaranAretina1='', this.vahaOlana1='', this.fombaFisehony1=''
            },

            rechercheCulture(){
                axios.get(apiUrl + 'api/recherche_culture_front', {params: {inpNom: this.inpNom, inpOrigine:this.inpOrigine}}).then(response => {
                    this.cultures = response.data
                    }).catch(error =>{
                    console.log(error)
                    })
            },

            detail_karazany(){
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_culture_karazany',{params:{idculture3: this.idculture1}}).then(response => {
                this.nom2 = response.data[0].anarana;
                this.description1 = response.data[0].mombamomba;
                
            })
            .catch(error => {(
                console.log(error)
            )})
            },

            detail_ankapobeny(){
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_ankapobeny',{params:{idculture3: this.idculture1}}).then(response => {
                this.toeTany1 = response.data[0].toeTany;
                this.fotoanaFambolena1 = response.data[0].fotoanaFambolena;
                this.toeTrandro1 = response.data[0].toeTrandro;
            })
            .catch(error => {(
                console.log(error)
            )})
            },

            detail_fambolena(){
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_fambolena',{params:{idculture3: this.idculture1}}).then(response => {
                this.fikarakaranaTany1 = response.data[0].fikarakaranaTany;
                this.famafazana1 = response.data[0].famafazana; 
                this.fikarakarana1 = response.data[0].fikarakarana;  
                this.famindrana1 = response.data[0].famindrana;
                this.fijinjana1 = response.data[0].fijinjana;
                this.fiotazana1 = response.data[0].fiotazana;
                this.fanetsana1 = response.data[0].fanetsana;
                this.fitehirizana1 = response.data[0].fitehirizana;
                this.fatraZezika1 = response.data[0].fatraZezika;
            })
            .catch(error => {(
                console.log(error)
            )})
            },

            detail_fahavalo(){
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_fahavalo',{params:{idculture3: this.idculture1}}).then(response => {
                this.anarana1 = response.data[0].anarana;
                this.mombamomba1 = response.data[0].mombamomba;
                this.asany1 = response.data[0].asany;
            })
            .catch(error => {(
                console.log(error)
            )})
            },

            detail_fitsaboana(){
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_fitsaboana',{params:{idculture3: this.idculture1}}).then(response => {
                this.anaranAretina1 = response.data[0].anaranAretina;
                this.vahaOlana1 = response.data[0].vahaOlana;
                this.fombaFisehony1 = response.data[0].fombaFisehony;
            })
            .catch(error => {(
                console.log(error)
            )})
            },

            getCultures(){
            axios.get(apiUrl + 'api/affichage_culture').then(response => {
              this.cultures = response.data
              // eslint-disable-next-line no-undef
              
            }).catch(error =>{
              console.log(error)
              this.cultures=[]
            })
      },

            d(){
                
                this.content_mode=JSON.parse(localStorage.getItem('content_mode'))
            
        },
        change_content_mode:function(data){
            this.content_mode=data
            //localStorage.setItem('content_mode',String(this.content_mode))
        },modal_detail_show:function(){
                if(this.modal_detail){
                    this.modal_detail=false
                    this.modal_back=false
                } else {
                    this.modal_detail=true
                    this.modal_back=true
                }
            },data_menu_mobile(data){
                this.menu_mobile2=data
            },
            data_modal_deconnnection(data){
                this.modal_deconnection=data
            },p_Ankapobeny:function(){
                this.Ankapobeny=true
                this.Karazany=false
                this.Fambolena=false
                this.Fahavalo=false
                this.Fitsaboana=false

            },
            p_Karazany:function(){
                this.Ankapobeny=false
                this.Karazany=true
                this.Fambolena=false
                this.Fahavalo=false
                this.Fitsaboana=false
            },
            p_Fambolena:function(){
                this.Ankapobeny=false
                this.Karazany=false
                this.Fambolena=true
                this.Fahavalo=false
                this.Fitsaboana=false
            },
            p_Fahavalo:function(){
                this.Ankapobeny=false
                this.Karazany=false
                this.Fambolena=false
                this.Fahavalo=true
                this.Fitsaboana=false
            },
            p_Fitsaboana:function(){
                this.Ankapobeny=false
                this.Karazany=false
                this.Fambolena=false
                this.Fahavalo=false
                this.Fitsaboana=true
            },
            generer_pdf:function(){
                // eslint-disable-next-line no-undef
                const pdf=new jsPDF();
                const img = require('@/assets/logoAgri.jpg')
                
                let yPos = 20; // Position verticale de départ
                const margin = 10; // Marge supérieure
                
                yPos += 10; // Augmentez la position verticale pour la prochaine ligne de texte
                const pageHeight = pdf.internal.pageSize.height;
                if (yPos + 10 > pageHeight) {
                    pdf.addPage();
                    yPos = margin;
                }
                pdf.addImage(img,'PNG',10,0,15,25)
                const date=new Date()

                
                pdf.setFontSize(16);
                pdf.text('Facture',10,10);
                const startY=40
                pdf.autoTable({
                    head: [['Description', 'Total']],
                    body: [
                         ['Abonnement Seera ','100 000 Ar'],
                         [{ 
                             content: 'Sous-total',
                             styles: { halign: 'right' 
                         } }, '100 000,00 Ar'],
                         [{ 
                             content: 'Crédit',
                             styles: { halign: 'right' } 
                         }, '0,00 Ar'],
                         [{ 
                             content: 'Total',
                             styles: { halign: 'right' } 
                         }, '100 000,00 Ar'],
                        ],
                        startY: startY ,
                    });
                   
                pdf.output('dataurlnewwindow')
            },
        }
    }
</script>