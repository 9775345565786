<!-- eslint-disable no-unused-vars -->
<template>
    <div>

        <br><br class="max-sm:hidden">
        <div class="bodyDetailsProd">
            <br><br>
            <div class="flex flex-col items-start justify-center max-sm:mx-2 md:mx-4">
                <div class="">
                    <span class="font-semibold"> Produit :</span>
                    <span class="px-4">{{ nomCulture }}</span>
                </div>
                <div>
                    <span class="font-semibold">Origine :</span>
                    <span class="px-4">{{ origine }}</span>
                </div>
            </div><br class="max-md:hidden">
            <div class="w-screen flex justify-beteen items-center sm:hidden px-2 py-3 gap-x-4">
                <div class="w-1/2">
                    <div class="bg-green-700 py-1 px-2  text-white flex justisy-center items-center gap-x-4"
                        @click="generer_pdf()">
                        <div class="fas fa-download"></div>
                        <div>
                            Generer en pdf
                        </div>
                    </div>
                </div>
                <div class="w-1/2">
                    <div class="px-4 flex justify-start items-center gap-x-4 py-1 border" @click="modal_menu = !modal_menu">
                        <div class="fas fa-angle-up" v-if="modal_menu"></div>
                        <div class="fas fa-angle-down" v-else></div>
                        <div>{{ modal_menu_value }}</div>
                    </div>
                </div>
            </div>
            <div class="mx-2 border">
                <div class="max-sm:hidden grid grid-cols-6">
                    <div class="bg-green-700   text-white cursor-pointer flex items-center justify-center gap-2 border-r border-white"
                        @click="download = true, generer_pdf()">
                        <div class="flex justify-center items-center">
                            <div class="fas fa-download"></div>
                        </div>
                        <div class="flex justify-center items-center ">
                            <div>
                                <h6 class="my-auto"> Telecharger</h6>
                            </div>
                        </div>
                    </div>
                    <div class=" page-1 py-1 cursor-pointer flex justify-center items-center border-r border-white"
                        :style="{ background: Ankapobeny ? 'white' : 'RGB(36 47 65)', color: Ankapobeny ? 'black' : 'white', borderTop: Ankapobeny ? '2px solid #0067b8' : 'none' }"
                        @click="p_Ankapobeny()">
                        <div>
                            <h6 class="my-auto py-1">Ankapobeny</h6>
                        </div>
                    </div>
                    <div class=" page-2 py-1 cursor-pointer flex justify-center items-center border-r border-white"
                        :style="{ background: Karazany ? 'white' : 'RGB(36 47 65)', color: Karazany ? 'black' : 'white', borderTop: Karazany ? '2px solid #0067b8' : 'none' }"
                        @click="p_Karazany()">
                        <h6 class="my-auto  py-1">Karazany</h6>
                    </div>
                    <div class=" page-3 py-1 cursor-pointer  flex justify-center items-center border-r border-white"
                        :style="{ background: Fambolena ? 'white' : 'RGB(36 47 65)', color: Fambolena ? 'black' : 'white', borderTop: Fambolena ? '2px solid #0067b8' : 'none' }"
                        @click="p_Fambolena()">
                        <h6 class="my-auto py-1">Fambolena</h6>
                    </div>
                    <div class=" page-4 py-1 cursor-pointer flex justify-center items-center border-r border-white"
                        :style="{ background: Fahavalo ? 'white' : 'RGB(36 47 65)', color: Fahavalo ? 'black' : 'white', borderTop: Fahavalo ? '2px solid #0067b8' : 'none' }"
                        @click="p_Fahavalo()">
                        <h6 class="my-auto py-1">Fahavalo</h6>
                    </div>
                    <div class=" page-5 py-1 cursor-pointer flex justify-center items-center"
                        :style="{ background: Fitsaboana ? 'white' : 'RGB(36 47 65)', color: Fitsaboana ? 'black' : 'white' }"
                        @click="p_Fitsaboana()">
                        <h6 class="my-auto py-1">Fitsaboana</h6>
                    </div>
                </div>

                <div class=" m-2 ">
                    <div class=""></div>
                    <div class=" m-2 ">
                        <div v-show="Karazany" class="flex flex-col justify-start items-start text-left w-full">
                            <div>
                                <div>
                                    <h6 class="font-semibold">Nom :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ nom1 }}</p>
                                </div>
                            </div>
                            <div class="">
                                <div>
                                    <h6 class="font-semibold">
                                        Description :
                                    </h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ description1 }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-show="Ankapobeny" class="flex flex-col justify-start items-start text-left w-full"
                            ref="ankapobeny">
                            <div>
                                <div>
                                    <h6 class="font-semibold">Toe-tany :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ toeTany1 }}</p>
                                </div>


                            </div>

                            <div>
                                <div>
                                    <h6 class="font-semibold">Fotoana Fambolena :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600 ">{{ fotoanaFambolena1 }}</p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Toetr'andro :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ toeTrandro1 }}</p>
                                </div>
                            </div>

                        </div>
                        <div v-show="Fahavalo" class="flex flex-col justify-start items-start text-left w-full">
                            <div>
                                <div>
                                    <h6 class="font-semibold">Anarana :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ anarana1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Mombamomba :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ mombamomba1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Asany :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ asany1 }}</p>
                                </div>


                            </div>
                        </div>
                        <div v-show="Fitsaboana" class="flex flex-col justify-start items-start text-left w-full">
                            <div>
                                <div>
                                    <h6 class="font-semibold">Anaran'aretina :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ anaranAretina1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Vahaolana :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ vahaOlana1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Fomba fisehona :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ fombaFisehony1 }}</p>
                                </div>


                            </div>
                        </div>
                        <div v-show="Fambolena" class="flex flex-col justify-start items-start text-left w-full">
                            <div>
                                <div>
                                    <h6 class="font-semibold">Fikarakarana ny tany :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ fikarakaranaTany1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Famafazana :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ famafazana1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Fikarakarana :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ fikarakarana1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Famindrana :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ famindrana1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Fijinjana :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ fijinjana1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Fiotazana :</h6>
                                </div>
                                <div>
                                    <p>{{ fiotazana1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Fanetsana :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ fanetsana1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Fitehirizana :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ fitehirizana1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Fatrazezika :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-600">{{ fatraZezika1 }}</p>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div class=""></div>
                </div>
            </div>
        </div><br><br><br>
        <Footer></Footer>
    </div>
    <div>
        <div class="py-2 px-5" ref="pdf" v-show="download">
            <div class="flex flex-col items-start justify-center">
                <h1 class="">
                    <span class="font-semibold"> Produit :</span>
                    <span class="px-4">{{ nomCulture }}</span>
                </h1>
                <h1>
                    <span class="font-semibold">Origine :</span>
                    <span class="px-4">{{ origine }}</span>
                </h1>
            </div>
            <div class="flex flex-col justify-start items-start text-left w-full bt-2">
                <div>
                    <div>
                        <h6 class="font-semibold">Nom :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ nom1 }}</p>
                    </div>
                </div>
                <div class="">
                    <div>
                        <h6 class="font-semibold">
                            Description :
                        </h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ description1 }}</p>
                    </div>
                </div>
            </div>
            <div class="flex flex-col justify-start items-start text-left w-full" ref="ankapobeny">
                <div>
                    <div>
                        <h6 class="font-semibold">Toe-tany :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ toeTany1 }}</p>
                    </div>


                </div>

                <div>
                    <div>
                        <h6 class="font-semibold">Fotoana Fambolena :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600 ">{{ fotoanaFambolena1 }}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Toetr'andro :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ toeTrandro1 }}</p>
                    </div>
                </div>

            </div>
            <div class="flex flex-col justify-start items-start text-left w-full">
                <div>
                    <div>
                        <h6 class="font-semibold">Anarana :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ anarana1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Mombamomba :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ mombamomba1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Asany :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ asany1 }}</p>
                    </div>


                </div>
            </div>
            <div class="flex flex-col justify-start items-start text-left w-full">
                <div>
                    <div>
                        <h6 class="font-semibold">Anaran'aretina :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ anaranAretina1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Vahaolana :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ vahaOlana1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Fomba fisehona :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ fombaFisehony1 }}</p>
                    </div>


                </div>
            </div>
            <div class="flex flex-col justify-start items-start text-left w-full">
                <div>
                    <div>
                        <h6 class="font-semibold">Fikarakarana ny tany :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ fikarakaranaTany1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Famafazana :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ famafazana1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Fikarakarana :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ fikarakarana1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Famindrana :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ famindrana1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Fijinjana :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ fijinjana1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Fiotazana :</h6>
                    </div>
                    <div>
                        <p>{{ fiotazana1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Fanetsana :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ fanetsana1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Fitehirizana :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ fitehirizana1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Fatrazezika :</h6>
                    </div>
                    <div>
                        <p class="text-gray-600">{{ fatraZezika1 }}</p>
                    </div>


                </div>

            </div>
        </div>
    </div>
    <Header2 @modal_deconnection_show="data_modal_deconnnection" @menu_mobile_show="data_menu_mobile"></Header2>
    <!-- modal notif download -->
    <div class="w-screen h-screen modal_ajout top-0 right-0 z-50 flex justify-center items-center bg-slate-700  bg-opacity-50" v-show="download">
        <div class="w-1/2 flex flex-col justify-center items-center bg-white p-4 shadow-sm shadow-gray-300">
            <div class="w-full flex justify-end items-center">
                <div class="fa fa-times fa-2x" @click="download = false"></div>
            </div>
            <div class="p-4 text-center">
                <p>
                    Le téléchargement est réussi!
                </p>
            </div>
        </div>
    </div>
    <!--modal deconnection-->
    <div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal-connection"
        v-show="modal_deconnection">
        <div class=" text-sm box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center">
            <div class="flex justify-end items-center">
                <div class="mx-4">
                    <button @click="modal_deconnection = false, modal_back = false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div class="flex flex-col gap-3">
                <div>
                    <h5 class="font-bold text-xl">Deconnnection</h5>
                </div>
                <div class="flex gap-3">
                    <div>
                        <button class="btn text-sm bg-gray-400 text-white p-1"
                            @click="modal_deconnection = false">Annuler</button>
                    </div>
                    <div>
                        <button class="btn text-sm bg-blue-500 text-white p-1"><Router-Link to="/">Se
                                deconnecter</Router-Link></button><br>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <!--modal menu mobile-->
    <div class=" fixed top-0 modal-mobile flex  w-screen h-screen bg-black bg-opacity-50 items-center justify-center"
        v-show="menu_mobile2">
        <div class=" text-sm fixed bg-white   w-[60vw] h-fit py-7 grid items-center justify-center sm:text-lg ">
            <div class="flex justify-end items-center">
                <div class=""><button class="fa fa-times fa-2x" @click="menu_mobile2 = false"></button></div>
            </div>
            <div class="p-6 flex flex-col gap-5 text-left">
                <div>
                    <RouterLink to="/Client/Accueil2" class="font-bold">
                        Accueil
                    </RouterLink>
                </div>
                <div>
                    <details>
                        <summary class="font-bold">Produit</summary>
                        <div class="flex flex-col p-1 gap-1 ml-5">
                            <div>
                                <RouterLink to="/Client/Produit">
                                    <h6>produit culture</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/ProduitElevage">
                                    <h6>produit agricole</h6>
                                </RouterLink>
                            </div>
                        </div>
                    </details>
                </div>
                <div>
                    <router-link to="/Client/Calendrier" class="font-bold">Calendrier</router-link>
                </div>
                <div>
                    <details>
                        <summary class="font-bold">Marché</summary>
                        <div class="flex flex-col p-1 gap-1  ml-5">
                            <div>
                                <RouterLink to="/Client/Prix">
                                    <h6>Prix</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/Client">
                                    <h6>Client</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/Fournisseur">
                                    <h6>Fournisseur</h6>
                                </RouterLink>
                            </div>
                        </div>
                    </details>
                </div>
                <div>
                    <details>
                        <summary class="font-bold">Outils à la decision</summary>
                        <div class="flex flex-col p-1 gap-1  ml-5">
                            <div>
                                <RouterLink to="/Client/Courbe">
                                    <h6>Courbe</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/ProduitAgricole">
                                    <h6>produit agricole</h6>
                                </RouterLink>
                            </div>
                        </div>
                    </details>
                </div>

            </div>
        </div>
    </div>
    <!--modal menu-->
    <!-- <div class="modal_ajout bg-black bg-opacity-50 justify-center items-center h-screen w-screen" > -->
    <Transition>
        <div class="bg-slate-700 text-white p-3  absolute z-12 top-52 right-2 w-[50vw] h-fit shadow-sm shadow-gray-300"
            v-show="modal_menu">
            <div class="flex flex-col gap-3">
                <div class="flex justify-end items-center">
                    <div class="fas fa-times " @click="modal_menu = false"></div>
                </div>

                <div class=" page-1" :style="{ border: Ankapobeny ? ' solid #009120' : 'white' }" @click="p_Ankapobeny()">
                    Ankapobeny</div>
                <div class=" page-2" :style="{ border: Karazany ? ' solid #009120' : 'white' }" @click="p_Karazany()">
                    Karazany
                </div>
                <div class=" page-3  " :style="{ border: Fambolena ? ' solid #009120' : 'white' }" @click="p_Fambolena()">
                    Fambolena
                </div>
                <div class=" page-4  " :style="{ border: Fahavalo ? ' solid #009120' : 'white' }" @click="p_Fahavalo()">
                    Fahavalo
                </div>
                <div class=" page-5  " :style="{ border: Fitsaboana ? ' solid #009120' : 'white' }" @click="p_Fitsaboana()">
                    Fitsaboana</div>
            </div>
        </div>
    </Transition>
    <!-- </div> -->
</template>
<style scoped>
.v-enter-active,
.v-leave-active {
    transition: transform 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    transform: translateX(500px);
}
</style>
<script>
import axios from 'axios';
import apiUrl from '../../apiConfig';
// import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import Footer from '@/components/Footer.vue'
import Header2 from '@/components/Header2.vue'
import 'jspdf-autotable';

import { Transition } from 'vue'
export default {
    name: "DetailsProd",
    components: {
        Footer,
        Header2,
    },
    data() {
        return {
            download: false,
            modal_menu_value: 'Ankapobeny',
            nom1: '', description1: '',
            toeTany1: '', fotoanaFambolena1: '', toeTrandro1: '',
            fikarakaranaTany1: '', famafazana1: '', fikarakarana1: '', famindrana1: '', fijinjana1: '', fiotazana1: '', fanetsana1: '', fitehirizana1: '', fatraZezika1: '',
            anarana1: '', mombamomba1: '', asany1: '',
            anaranAretina1: '', vahaOlana1: '', fombaFisehony1: '',
            nomCulture: '', origine: '',
            modal_menu: false,
            Ankapobeny: true,
            Karazany: false,
            Fambolena: false,
            Fahavalo: false,
            Fitsaboana: false, sections: [
                { id: 'Service', title: 'Service', isActive: false },
                { id: 'Propos', title: 'Propos', isActive: false },
                { id: 'Pricing', title: 'Pricing', isActive: false },
                { id: 'Contact', title: 'Contact', isActive: false },
                // Add more sections as needed
            ], menu_mobile2: false,
            modal_deconnection: false
        }
    },
    mounted() {
        this.detail_produit();
        this.detail_karazany();
        this.detail_ankapobeny();
        this.detail_fambolena();
        this.detail_fahavalo();
        this.detail_fitsaboana();
    },
    methods: {
        detail_produit() {
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_culture_detail', { params: { idculture3: this.$route.params.idculture } }).then(response => {
                this.nomCulture = response.data[0].nom;
                this.origine = response.data[0].origine;
            })
                .catch(error => {
                    (
                        console.log(error)
                    )

                })
        },
        detail_karazany() {
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_culture_karazany', { params: { idculture3: this.$route.params.idculture } }).then(response => {
                this.nom1 = response.data[0].anarana;
                this.description1 = response.data[0].mombamomba;
            })
                .catch(error => {
                    (
                        console.log(error)
                    )
                })
        },
        detail_ankapobeny() {
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_ankapobeny', { params: { idculture3: this.$route.params.idculture } }).then(response => {
                this.toeTany1 = response.data[0].toeTany;
                this.fotoanaFambolena1 = response.data[0].fotoanaFambolena;
                this.toeTrandro1 = response.data[0].toeTrandro;
            })
                .catch(error => {
                    (
                        console.log(error)
                    )
                })
        },
        detail_fambolena() {
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_fambolena', { params: { idculture3: this.$route.params.idculture } }).then(response => {
                this.fikarakaranaTany1 = response.data[0].fikarakaranaTany;
                this.famafazana1 = response.data[0].famafazana;
                this.fikarakarana1 = response.data[0].fikarakarana;
                this.famindrana1 = response.data[0].famindrana;
                this.fijinjana1 = response.data[0].fijinjana;
                this.fiotazana1 = response.data[0].fiotazana;
                this.fanetsana1 = response.data[0].fanetsana;
                this.fitehirizana1 = response.data[0].fitehirizana;
                this.fatraZezika1 = response.data[0].fatraZezika;
            })
                .catch(error => {
                    (
                        console.log(error)
                    )
                })
        },
        detail_fahavalo() {
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_fahavalo', { params: { idculture3: this.$route.params.idculture } }).then(response => {
                this.anarana1 = response.data[0].anarana;
                this.mombamomba1 = response.data[0].mombamomba;
                this.asany1 = response.data[0].asany;
            })
                .catch(error => {
                    (
                        console.log(error)
                    )
                })
        },
        detail_fitsaboana() {
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_fitsaboana', { params: { idculture3: this.$route.params.idculture } }).then(response => {
                this.anaranAretina1 = response.data[0].anaranAretina;
                this.vahaOlana1 = response.data[0].vahaOlana;
                this.fombaFisehony1 = response.data[0].fombaFisehony;
            })
                .catch(error => {
                    (
                        console.log(error)
                    )
                })

                .catch(error => {(
                    console.log(error)
                )})
            },
            p_Ankapobeny:function(){
                this.Ankapobeny=true
                this.Karazany=false
                this.Fambolena=false
                this.Fahavalo=false
                this.Fitsaboana=false
            },
            p_Karazany:function(){
                this.Ankapobeny=false
                this.Karazany=true
                this.Fambolena=false
                this.Fahavalo=false
                this.Fitsaboana=false
            },
            p_Fambolena:function(){
                this.Ankapobeny=false
                this.Karazany=false
                this.Fambolena=true
                this.Fahavalo=false
                this.Fitsaboana=false
            },
            p_Fahavalo:function(){
                this.Ankapobeny=false
                this.Karazany=false
                this.Fambolena=false
                this.Fahavalo=true
                this.Fitsaboana=false
            },
            p_Fitsaboana:function(){
                this.Ankapobeny=false
                this.Karazany=false
                this.Fambolena=false
                this.Fahavalo=false
                this.Fitsaboana=true
            },
            addLine(data){
                return data+10
            }
            ,
            generer_pdf:function(){
                const pdf=new jsPDF('p', 'mm', [210, 297]);
                // eslint-disable-next-line no-unused-vars
                const img = require('@/assets/logoAgri.jpg')
                let yPos = 20; // Position verticale de départ
                const margin = 10; // Marge supérieure
                yPos += 10; // Augmentez la position verticale pour la prochaine ligne de texte
                const pageHeight = pdf.internal.pageSize.height;
                if (yPos + 10 > pageHeight) {
                    pdf.addPage();
                    yPos = margin;
                }
                // pdf.addImage(img,'PNG',10,0,15,25)
               
                // eslint-disable-next-line no-unused-vars
                const y=10
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.setTextColor('blue')
                pdf.text(this.nomCulture + ' - ' + this.origine,100, 10, 'center');
                pdf.setTextColor('black')
                
                pdf.text("1. Ankapobeny",15,20)
                pdf.text("1.1. Toe-tany:",15,30)
                // eslint-disable-next-line no-undef
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.splitTextToSize(this.toeTany1, 100)
                pdf.text(this.toeTany1,25,38)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("1.2. Fotoana Fambolena",15,48)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.fotoanaFambolena1,25,56)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("1.3. Toetr'andro",15,66)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.toeTrandro1,25,74)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("2. Karazany",15,84)
                pdf.text("2.1. Anarana:",15,94)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.anarana1,25,104)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("2.2. Mombamomba",15,114)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.description1,25,124)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("3. Fambolena",15,134)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("3.1. Fikarakarana ny tany:",15,144)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.fikarakaranaTany1,25,154)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("3.2. Famafazana",15,164)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.famafazana1,25,174)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("3.3. Fikarakarana",15,184)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.fikarakarana1,25,194)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("3.4. Famindrana:",15,204)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.famindrana1,25,214)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("3.5. Fijinjana",15,224)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.fijinjana1,25,234)
               
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("3.6. Fiotazana",15,244)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.fiotazana1,25,254)
                
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("3.7. Fanetsana:",15,264)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.fanetsana1,25,274)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("3.8. Fitehirizana",15,284)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.fitehirizana1,25,294)
                pdf.addPage()
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("3.9. Fatra zezika",15,10)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.fatraZezika1,25,20)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("4. Fitsaboana",15,30)
                pdf.text("4.1. Anaran'aretina:",15,40)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.anaranAretina1,25,50)
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("4.2. Vahaolana",15,60)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.vahaOlana1,25,70)
               
                pdf.setFont('Arial','bold')
                pdf.setFontSize(12)
                pdf.text("4.3. Fomba fisehona",15,80)
                pdf.setFont('Arial','normal')
                pdf.setFontSize(12)
                pdf.text(this.fombaFisehony1,25,90)
                
                pdf.output('dataurlnewwindow')
            },
            data_menu_mobile(data){
                this.menu_mobile2=data
            },
            data_modal_deconnnection(data){
                this.modal_deconnection=data

        },
        p_Ankapobeny: function () {
            this.Ankapobeny = true
            this.Karazany = false
            this.Fambolena = false
            this.Fahavalo = false
            this.Fitsaboana = false
            this.modal_menu_value = 'Ankaponeny'
        },
        p_Karazany: function () {
            this.Ankapobeny = false
            this.Karazany = true
            this.Fambolena = false
            this.Fahavalo = false
            this.Fitsaboana = false
            this.modal_menu_value = 'Karazany'
        },
        p_Fambolena: function () {
            this.Ankapobeny = false
            this.Karazany = false
            this.Fambolena = true
            this.Fahavalo = false
            this.Fitsaboana = false
            this.modal_menu_value = 'Fambolena'
        },
        p_Fahavalo: function () {
            this.Ankapobeny = false
            this.Karazany = false
            this.Fambolena = false
            this.Fahavalo = true
            this.Fitsaboana = false
            this.modal_menu_value = 'Fahavalo'
        },
        p_Fitsaboana: function () {
            this.Ankapobeny = false
            this.Karazany = false
            this.Fambolena = false
            this.Fahavalo = false
            this.Fitsaboana = true
            this.modal_menu_value = 'Fitsaboana'
        },
        addLine(data) {
            return data + 12
        }
        ,
        generer_pdf: function () {

            if (this.download = true) {
                const options = {
                    filename: 'mi_archivo.pdf',
                    image: { type: 'jpeg', quality: 1 },
                    html2canvas: {},
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
                };

                // Elemento a convertir
                const content = this.$refs.pdf;


                // Iniciar la conversión a PDF
                html2pdf().from(content).set(options).save();

            }

        },
        data_menu_mobile(data) {
            this.menu_mobile2 = data
        },
        data_modal_deconnnection(data) {
            this.modal_deconnection = data
        }
    }
}
</script>