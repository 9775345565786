<template>
    <br>
    <br>
    <!-- footer pour md+ -->
    <div class="footeContainer max-md:hidden md:block md-fixed container-fluid  sm:text-base text-white px-1 bottom-0 bg-slate-800">
        <div class="w-screen p-4 flex flex-col">
            
            <div class="flex w-full text-left justify-between items-start gap-9">
                <!-- <h4 class="font-bold text-lg">Contacter nous</h4> -->
                <div class="">
                    <h6 class="font-bold">Email</h6>
                    <p class=" text-sm text-gray-300 ">info@haisoa.com</p>
                </div>
                <div  class="">
                    <h6 class="font-bold">Telephone</h6>
                    <p  class=" text-sm  text-gray-300">+261 33 70 879 64</p>
                    <p class=" text-sm text-gray-300"> +261389875472 </p>
                </div>
                <div  class="">
                    <h6 class="font-bold">Adresse</h6>
                    <p class="text-sm text-x text-gray-300">Lot VK 17 à Fenomanana Mahazoarivo Antananarivo</p>
                </div>
            </div>
            
        </div>
        <div class="grid grid-cols-3 items-center justify-center">
            <div>
                <hr>
            </div>
            <!--logo reseaux-->
            <div class="flex flex-col justify-center items-center">
                <div><p>Contacter nous sur les reseau sociaux</p></div>
                
                <div class="flex gap-5 justify-center items-center">
                    
                    <div class="flex gap-7 justify-center items-center">
                        <div class="fab fa-facebook fa-2x cursor-pointer" ></div>
                        <div class="fab fa-twitter-square fa-2x cursor-pointer"></div>
                        <div class="fab fa-instagram fa-2x cursor-pointer"></div>
                    </div>
                    
                </div>
            </div>
            <div>
                <hr> 
            </div>
        </div>
        <br>
        <div>
            <p class="text-xs sm:text-base"><router-link to="./Admin/ClientAdmin" style="text-decoration: none; color: inherit;">ⓒ</router-link> Copyright <a href="http://www.haisoa.com" class="text-blue-200">HAISOA</a>  2023. Tous droits réservés.</p>
            <div>
                
            </div>
        </div>
    </div>
    <!-- footer pour mobile -->
    <div class="footeContainer md:hidden max-sm:md:block container-fluid  sm:text-base text-white py-2 px-1 bottom-0 bg-slate-800">
        <div class="w-screen mx-2 flex flex-col">
            <div class="flex flex-col w-full text-left justify-center items-start gap-2">
                <!-- <h4 class="font-bold text-lg">Contacter nous</h4> -->
                <div class="flex gap-x-4 ">
                    <div class="fas fa-envelope"></div>
                    <div>
                        <p class=" text-sm text-gray-300 ">info@haisoa.com</p>
                    </div>
                </div>
                <div  class="flex gap-x-4 ">
                    <div class="fas fa-phone"></div>
                    <div class="flex  justify-center items-center">
                        <div><p  class=" text-sm  text-gray-300">+261 33 70 879 64 / <span> </span></p></div>
                        <div><p class=" text-sm text-gray-300"> +261 38 98 754 72 </p></div>
                    </div>
                </div>
                <div  class="flex gap-x-4 ">
                    <div class="fas fa-location-dot"></div>
                    <div>
                        <p class="text-sm  text-gray-300">Lot VK 17 à Fenomanana Mahazoarivo Antananarivo</p>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="flex  items-center justify-center">
            <!--logo reseaux-->
            <div class="flex flex-col justify-center items-center">
                <div><p class="text-sm text-gray-300">Contacter nous sur les reseau sociaux</p></div>
                
                <div class="flex gap-5 justify-center items-center">
                    
                    <div class="flex gap-7 justify-center items-center">
                        <div class="fab fa-facebook fa-2x cursor-pointer" ></div>
                        <div class="fab fa-twitter-square fa-2x cursor-pointer"></div>
                        <div class="fab fa-instagram fa-2x cursor-pointer"></div>
                    </div>
                    
                </div>
            </div>
        </div>
        <br>
        <div>
            <p class="text-sm"><router-link to="./Admin/ClientAdmin" style="text-decoration: none; color: inherit;">ⓒ</router-link> Copyright <a href="http://www.haisoa.com" class="text-blue-200">HAISOA</a>  2023. Tous droits réservés.</p>
            <div>
                
            </div>
        </div>
    </div>
        
    
    <!--modal up-->
</template>

<script>
import '@/components/style/footer.css'
    export default {
        data(){
            return {

            }
        }
    }
</script>