<!-- eslint-disable vue/no-deprecated-destroyed-lifecycle -->
<!-- eslint-disable vue/require-v-for-key -->
` <template>
    
    <div @click="hide()" class="w-screen ">
        <br><br>
        <div>
            
   <div class="w-screen flex justify-between items-center carousel bg-white py-2 max-sm:px-5 sm:px-12">
        <!-- <div class="carousel-item w-screen"> -->
        <div class="md:w-1/2 max-md:hidden lg:block flex justify-start items-center">
            <div class="flex justify-start items-center h-full">
                <div class="h-fit">
                    <h1 class="text-left p-2 font-bold max-sm:text-lg sm:text-2xl lg:text-5xl">Agribusiness</h1>
                    <p class="text-left max-sm:text-sm text-gray-600">Bienvenue sur notre plateforme dédiée à l'information agricole, où les données les plus récentes se combinent à des analyses 
                        approfondies pour éclairer vos décisions et optimiser vos rendements. <br> Nous permettons de fournir des divers informations agricoles et des conseils pratiques sur ce domaine. <br> <br>  </p>
                    <div class="w-full flex items-center justify-start gap-x-2">
                        <div>
                            <button class="px-2 py-1  border-2 border-blue-400 text-blue-400 hover:border-blue-500 hover:text-white hover:bg-blue-500" @click="$router.push('/Client/Inscription')">S'inscrire</button>
                        </div>
                        <div>
                            <button class="max-lg:px-1 lg:px-4 py-1  border-2 border-blue-400 hover:border-blue-500 text-white bg-blue-400 hover:bg-blue-500" @click="$router.push('/Client/Connection')">Se connecter</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md:w-1/2 max-md:hidden lg:block">
           <div class="w-full"><img src="./../assets/img.jpeg " alt="image panel" class="clip-path-polygon" ></div>
        </div>
        <div class="w-screen md:hidden  flex-col justify-center items-center">
            <div class="w-screen  max-md:block lg:hidden ">
           <div class="w-full"><img src="./../assets/img.jpeg " alt="image panel" class="" ></div>
        </div>
        <div class="max-md:block lg:hidden  justify-start items-center ">
            <div class="flex justify-start items-center h-full">
                <div class="h-fit">
                    <h5 class="text-left p-2 font-bold mt-4">Agribusiness</h5>
                    <p class="text-left max-sm:text-sm text-gray-600">Bienvenue sur notre plateforme dédiée à l'information agricole, où les données les plus récentes se combinent à des analyses 
                        approfondies pour éclairer vos décisions et optimiser vos rendements. <br> Nous permettons de fournir des divers informations agricoles et des conseils pratiques sur ce domaine.  <br> <br> Inscrivez-vous pour accéder à des informations exclusives.</p>
                        <div class="w-11/12 flex items-center px-2 justify-start gap-x-3">
                        <div class="w-1/2">
                            <button class="px-1 py-1 w-full  border-2 border-blue-400 text-blue-400 hover:border-blue-500 hover:text-white hover:bg-blue-500" @click="$router.push('/Client/Inscription')">S'inscrire</button>
                        </div>
                        <div class="w-1/2">
                            <button class="px-1 py-1  w-full  border-2 border-blue-400 hover:border-blue-500 text-white bg-blue-400 hover:bg-blue-500" @click="$router.push('/Client/Connection')">Se connecter</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
   </div>
   <br><br>
    <div class="flex w-screen  flex-col justify-center items-center ">
           <div id="Service" class=" w-screen  bg-slate-50">
               <h2 class="max-sm:text-xl sm:text-3xl font-semibold py-2">Service</h2>
               <div class="mx-10">
                   <p class=" max-sm:text-sm text-justify  text-gray-600 ">
                       Explorez le monde de l'agriculture avec nos informations exclusives, vous offrant des analyses approfondies, des client et fournisseurs des produits agricoles, nos services de conseils
                       en gestion agricole pour optimiser la rentabilité de votre exploitation. Découvrez la différence avec notre service exceptionnel, dédiée à surpasser vos attentes et à 
                       ouvrir de nouvelles perspectives dans votre domaine.
                   </p>
                   <br>
               </div>
               <div class="grid max-md:grid-cols-none max-lg:grid-cols-2 lg:grid-cols-3 w-fit  gap-3 text-sm py-8 sm:text-base justify-center items-start max-sm:px-5 sm:px-16">
                       <div class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300  items-start justify-center gap-3 ">

                                   <div class="w-full flex justify-start items-center">
                                      <div class="w-full flex items-center max-sm:gap-x-5 sm:gap-x-9">
                                        <div class="fas fa-user fa-3x"></div>
                                        <div><h5 class=" max-sm:text-lg sm:text-xl font-bold">Client</h5></div>
                                      </div>
                                   </div>
                                   <div class="w-full mx-2">
                                       
                                       <br>
                                       <div class="text-left">
                                       <p class="text-gray-600">
                                           Découvrez notre large clientèle diversifiée, englobant tous les domaines de l'agriculture et de l'élevage, qui veulent des produits agricoles et élevages.
                                            
                                       </p>
                                   </div>
                                   </div>
                       </div>
                       <div class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                                   <div class="w-full flex justify-start items-center">
                                      <div class="w-full flex items-center gap-x-9">
                                        <div class="fas fa-hand-holding-hand fa-3x"></div>
                                        <div><h5 class=" max-sm:text-lg sm:text-xl font-bold">Fournisseur</h5></div>
                                      </div>
                                   </div>
                                   <div class="w-full mx-2">
                                       
                                       <br>
                                       <div class="text-left">
                                       <p class="text-gray-600">
                                           Découvrez une multitude de fournisseur dans tous les domaines de l'agriculture et de l'élevage sur notre plateforme, qui ont des produits agricoles et élevages.
                                            
                                       </p>
                                   </div>
                                   </div>
                       </div>
                       <div class="flex flex-col p-3 sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                                   <div class="w-full flex justify-start items-center">
                                      <div class="w-full flex items-center justify-center max-sm:gap-x-4 sm:gap-x-8">
                                        <div class="fas fa-seedling fa-3x md:pr-2"></div>
                                        <div><h5 class=" max-sm:text-lg sm:text-xl font-bold">Produit agricole</h5></div>
                                      </div>
                                   </div>
                                   <div class="w-full mx-4">
                                       
                                       <br>
                                       <div class="text-left">
                                       <p class="text-gray-600">
                                           Explorez les divers étapes minutieuses de la culture, depuis la préparation du sol jusqu'à la récolte, pour vous offrir 
                                           des produits cultivés avec passion et expertise.
                                            
                                       </p>
                                   </div>
                                   </div>
                       </div>
                       <div class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                                   <div class="w-full flex justify-start items-center">
                                      <div class="w-full flex items-center gap-x-9">
                                        <div class="fas fa-cow fa-3x md:pr-2"></div>
                                        <div><h5 class=" max-sm:text-lg sm:text-xl font-bold">Produit elevage</h5></div>
                                      </div>
                                   </div>
                                   <div class="w-full mx-4">
                                       
                                       <br>
                                       <div class="text-left">
                                       <p class="text-gray-600" >
                                           Explorez les étapes respectueuses de notre processus d'élevage, où le confort, la nutrition, et la santé de nos animaux sont prioritaires.
                                            
                                       </p>
                                   </div>
                                   </div>
                       </div>
                       <div class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                                   <div class="w-full grid grid-cols-2flex justify-start items-center">
                                      <div class="w-full flex items-center gap-x-9">
                                        <div class="fas fa-chart-line fa-3x"></div>
                                        <div><h5 class=" max-sm:text-lg sm:text-xl font-bold">Courbe</h5></div>
                                      </div>
                                   </div>
                                   <div class="w-full mx-4">
                                       
                                       <br>
                                       <div class="text-left">
                                       <p class="text-gray-600">
                                           Vous pouvez accéder au courbe montrant les prix des différents produits agricoles des année précedents qui vous aidera à prendre des décisions sur les marchés.
                                            
                                       </p>
                                   </div>
                                   </div>
                       </div>
                       <div class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                                   <div class="w-full flex justify-start items-center">
                                      <div class="w-full flex items-center gap-x-9">

                                        <div class="fas fa-calendar-days fa-3x"></div>
                                        <div><h5 class=" max-sm:text-lg sm:text-xl font-bold">Calendrier agricole</h5></div>

                                      </div>
                                   </div>
                                   <div class="w-full mx-4">
                                       
                                       <br>
                                       <div class="text-left">
                                       <p class="text-gray-600">
                                        Découvrez notre calendrier agricole, un guide saisonnier qui vous accompagne au long de l'année avec les dates respectives pour les 
                                        différents pratiques agricoles.
                                            
                                       </p>
                                   </div>
                                   </div>
                       </div>
                       
                      
               </div>
           </div>
           <br>
           <div id="Propos">

               <h2 class="text-xl sm:text-3xl  font-semibold">A propos</h2><br>
               <div>
                <p class=" md:px-5 max-md:mx-6 max-lg:mx-10 lg:px-12  max-sm:text-sm text-justify text-gray-600">
                   Notre plateforme web est bien plus qu'un simple espace en ligne.
                   Grâce à notre service, vous pouvez accéder à une vaste liste de clients qui cherchent des produits de qualité. Nous mettons également à votre disposition 
                   un éventail de fournisseurs dans tous les domaines de l'agriculture et de l'élevage.
                   Vous avez de l'accès sur le calendrier agricole pour rester en phase avec les cycles naturels. Des recommandations saisonnières détaillées vous guideront dans la gestion 
                   optimale de votre exploitaion, de la préparation du sol à la récolte.
                   
               </p><br>
               </div>
               
               <div class="grid grid-row-2 gap-y-20 max-sm:px-5 sm:px-16 py-8">
                   

                   <div class="sm:flex md:flex md:flex-col lg:grid lg:grid-cols-2 h-fit border gap-3 w-fit ">
                       <div class="w-full ">
                           <img src="./../assets/pexels-cottonbro-studio-4911708.jpg" class="" alt="">
                       </div>
                       <div class="flex items-start justify-start  text-justify m-auto p-4">
                          <div class="">
                            <div class="">
                                <h6 class="font-semibold">Produit d'élevage</h6>
                                <p class="max-md:text-sm w-full text-justify text-gray-600">
                                    Explorez notre technique d'élevage qui marie habilement des pratiques modernes avec une tradition d'excellence. Des installations de pointe sont combinées
                                    à des méthodes éprouvées pour offrir des produits qui répondent aux normes les plus élevées de qualité et de durabilité. Les méthodes traditionnelles sont fusionnées avec 
                                    des normes modernes pour créer des produits d'élevage qui incarnent une qualité supérieur et une attention méticuleuse à  chaque détail...
                                    des normes modernes pour créer des produits d'élevage qui incarnent une qualité supérieur et une attention méticuleuse à  chaque détail. <br><br>
                                <!-- Découvrez notre approche consciente de l'élevage, où le respect des animaux et de l'environnement est fondamental. Chaque phase du processus reflète notre notre -->
                                <!-- engagement envers la durabilité, depuis les conditions de vie confortables jusqu'à une alimentation équilibrée. Le résultat est une gamme exceptionnelle de produits d'élevage éthiques. <br><br> -->
                                </p> 
                                <div class="flex justify-start items-center gap-x-4 text-blue-600  cursor-pointer">
                                    <div class="">En savoir plus</div><div class="fas fa-arrow-right"></div>
                                </div>
                            </div>
                          </div>
                       </div>
                   </div>
                   <div class="sm:flex md:flex md:flex-col-reverse lg:grid lg:grid-cols-2 border h-fit gap-3 w-fit ">
                    <div class="flex items-start justify-start  text-justify mx-auto p-4">
                          <div class="">
                            <div class="">
                                <h6 class="font-semibold">Produit d'agriculture</h6>
                                <p class="max-md:text-sm text-gray-600 w-full text-justify">
                                    Bienvenue dans notre parcours de production agricole, soigneusement élaboré pour garantir des produits de la plus haute qualité.
                               Tout commence par la sélection attentive des semences, suivie d'une préparation méticuleuse du sol pour assurer des conditions de croissance optimale. 
                               Nous accordons une attention particulière à chaque étape, de la germination des cultures à la récolte finale, pour vous offrir des produits cultivés avec dévouement et expertise.<br>
                               Explorez avec nous le cycle complet de la production agricole, commençant par les  semailles qui marquent le début de notre engagement envers la croissance durable. ...
                              <!-- À mesure que les cultures grandissent, nous veillons à un entretien attentif, avec des pratiques agricoles respectueuses de l'environnement. La récolte, point culminant
                               de notre processus, symbolise la concrétisation de nos efforts, offrant des produits de  première qualité. -->
                                </p>
                                <div class="flex justify-start items-center gap-x-4 text-blue-600  cursor-pointer">
                                    <div class="">En savoir plus</div><div class="fas fa-arrow-right"></div>
                                </div>
                            </div>
                          </div>
                       </div>
                       <div class="">
                           <img src="./../assets/pexels-mark-stebnicki-2749165.jpg" class="" alt="">
                       </div>
                   </div>
               </div>
           </div>
           <br>
           <hr>
           <br>
           <div id="Pricing" class="overflow-x-auto">
               <h2 class="text-xl sm:text-3xl  font-semibold">Abonnement</h2>
               <Pricing></Pricing>
           </div>
           <br>
           <hr>
           <br>
           <div class="grid justify-center items-center w-screen" id="Contact">
            <div class="bg-white  overflow-hidden w-screen relative z-10">
   <div class="flex flex-col justify-start w-screen items-center">
    <div class="">
        <h1 class="max-sm:text-xl sm:text-3xl  font-semibold">
            Contact
        </h1>
    </div>
      <div class="sm:flex max-sm:flex-col w-screen sm:px-4 sm:justify-between sm:items-start sm:gap-x-10">
         <div class="sm:w-1/2 max-sm:w-full max-sm:px-4">
            <div class="w-full mb-12 lg:mb-0">
               <h2
                  class="
                  text-dark
                  mb-6
                  uppercase
                  font-bold
                  text-xl
                  "
                  >
               </h2>
               <p class="max-sm:text-sm text-justify text-body-color leading-relaxed mb-9 text-gray-600">
                  N'hesitez pas à nous contacter pour toute question, demande d'information ou assistance. <br> Nous sommes là pour vous aider.
               </p>
            </div>
         </div>
         <div class=" sm:w-1/2  max-sm:w-full">
               <form class="w-full flex flex-col items-start justify-center px-4" @submit.prevent="envoyerMessage">
                  <div class="mb-3 w-full">
                     <input
                        type="text"
                        placeholder="Votre nom"
                        class="
                        w-full
                        placeholder:text-sm
                        py-1
                        px-1
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                        v-model="nom" required
                        />
                  </div>
                  <div class="mb-3 w-full">
                     <input
                        type="email"
                        placeholder="votre e-mail"
                        class="
                        w-full
                        placeholder:text-sm
                        py-1
                        px-1
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                        v-model="email" required
                        />
                  </div>
                  <div class="mb-3 w-full">
                     <input
                        type="text"
                        placeholder="Votre numéro"
                        class="
                        w-full
                        placeholder:text-sm
                        py-1
                        px-1
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                        v-model="numero" required
                        />
                  </div>
                  <div class="mb-3 w-full">
                     <textarea
                        rows="6"
                        placeholder="Votre message"
                        class="
                        w-full
                        
                        py-1
                        px-1
                        text-body-color text-base
                        border border-[f0f0f0]
                        resize-none
                        outline-none
                        placeholder:text-sm
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                        v-model="message" required
                        ></textarea>
                  </div>
                  <div class="w-full">
                     <button
                        type="submit"
                        class="
                        w-full
                        text-white
                        bg-blue-400 hover:bg-blue-500
                        px-1
                        border border-blue-400 hover:border-blue-500
                         py-1
                        transition
                        "
                        >
                        Envoyer
                     </button>
                  </div>
               </form>
               <div>
               </div>
            
         </div>
      </div>
   </div>
</div>
            </div>
   </div>
    </div>
    <!-- </div> -->
    
    <!--modal menu mobile-->

    <div class="modal_header transition-all   duration-500 w-screen h-fit   overflow-hidden"  ref="modal_menu" >
        <div class="">
            <div class="flex justify-between  px-4 bg-white h-fit overflow-hidden text-sm sm:text-base items-center">
                <div class="">
                    <router-link to="/">
                        <img src="./img/logovf.png" class="w-7 sm:w-10">
                    </router-link>
                </div>
                <div class="max-sm:hidden sm:block  overflow-hidden h-fit">
                    <div class="flex  lg:gap-10  overflow-hidden  max-sm:text-sm items-center justify-between h-fit">
                        <div  v-for="(section, index) in sections" :key="index" class="px-3 h-fit">
                            <a class=" overflow-hidden  no-underline text-gray-800  h-fit  transition duration-500text-lg hover:text-blue-500" :href="`#${section.id}`" :class="{ active: section.isActive }">{{ section.title }}</a>
                        </div>
                    </div>
                </div>
                <div class="flex  items-center justify-end">
                    <div class="">
                        <router-link to="/Client/Connection"><button  class="bg-blue-400 py-1 px-4 hover:bg-blue-500 text-white my-3 mx-2" >Se connecter</button></router-link>
                    </div>
                    <div class="sm:hidden flex justify-center items-center">
                        <button @click="show()" ref="btn_menu" class="active:bg-gray-200 fas text-black w-7 fa-bars-staggered fa-2x transition-all   duration-500" v-show="!modal_menu"></button>
                        <button @click="show()" ref="btn_menu" class="active:bg-gray-200 fas text-black fa-times w-7 fa-2x transition-all   duration-500"  v-show="modal_menu"></button>
                    </div>
                </div>
            </div>
            <Transition>
                <div  v-show="modal_menu" class=" bg-slate-700 w-screen overflow-hidden">
                <ul class="m-2">
                    <li v-for="item in sections" class="divide-y my-2 text-left ">
                        
                        <a :href="item.link" class=" no-underline border-y p-2 my-2 mb-2 text-white">{{ item.title }}</a>
                        
                    </li>
                    
                </ul>
            </div>
            </Transition>
        </div>
    </div>

    <div class="modal_ajout fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start" v-show="modalValidation">
        <div class="bg-white flex flex-col justify-center items-center shadow-sm shadow-gray-300 p-4 mt-20">
            <div class="w-full flex justify-end items-center">
                <div class="fa fa-times cursor-pointer" @click="modalValidation = false"></div>
            </div>
            <div class="fas fa-check rounded-full border-2 fa-2x text-green-400 border-green-400 p-2"></div>
            <div class="w-9/12">
                <p class="text-gray-500">Votre message a été bien envoyé !</p>
            </div>
        </div>
    </div>


</template>
<style scoped>

.v-enter-active ,.v-leave-active{
    transition:transform 0.5s ease;
}
.v-enter-from,.v-leave-to {
    transform: translateX(500px);
}
.clip-path-polygon{
    clip-path: polygon(15% 0 ,100% 0,100% 100%,0 100%);
}
    .bg-url{
        background: url('./../assets/pexels-pixabay-265278.jpg');
    }
    .carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carousel-container {
  display: flex;
  transition: transform 0.5s ease;
}


img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}
*{
    overflow-x: hidden;
}
.trans {
    transition: all 500ms ease-in-out;
}
.div_front{
    position: absolute;
    z-index: 3;
}
</style>
<script>
//import { RouterLink } from 'vue-router';
import '@/components/style/header.css';
import '@/components/style/bodyAccueil.css';
import '@/components/style/all.css';
import axios from 'axios'
import apiUrl from '../apiConfig';

import Pricing from './Pricing.vue';
//import $ from 'jquery';
export default{
    components:{
       Pricing
    },
    data(){
        return{
            height:'0',
            nom:'', email:'', numero:'', message:'',
            modal_pricing_1:false,
            modal_pricing_2:false,
            modalValidation: false,
            modal_pricing_3:false,
            menu_mobile:false,
            observer:null,
            modal_connection: false,
            modal_back:false,
            btn_connection:true,
            inp_password:'',
            show_password:false,
            overflow:{
            overflow:'scroll',
            
            },modal_menu:false,
            sections: [
          { id: 'Service', title: 'Service', link: '#Service' },
          { id: 'Propos', title: 'A propos', link: '#Propos' },
          { id: 'Pricing', title: 'Abonnement', link: '#Pricing'},
          { id: 'Contact', title: 'Contact', link:'#Contact' },
          // Add more sections as needed
        ],isActive:false,currentIndex:0,items:[]
        }
        },
        mounted(){
//            this.items = $('.carousel').children();
//            this.currentIndex = 0;

// setInterval(() => {
//     this.items.eq(this.currentIndex).css('display', 'none');
//     this.currentIndex++;

//     if (this.currentIndex >= this.items.length) {
//         this.currentIndex = 0;
//     }

//     this.items.eq(this.currentIndex).css('display', 'block');
// }, 2000);

            window.addEventListener("scroll", () => {
    // Get the current scroll position
    const scrollY = window.scrollY;
    if(this.$refs.modal_menu !== null || this.$refs.btn_menu !== null){

    // Check if the scroll has reached the bottom
    if (scrollY < 70) {
      // Add 'bg-opacity-0' class to the element with 'modal_menu' ref
    //   this.$refs.modal_menu.classList.add('bg-opacity-0');

    //   this.$refs.modal_menu.classList.remove('bg-slate-50');
      this.$refs.modal_menu.classList.remove('shadow-sm');
      this.$refs.modal_menu.classList.remove('shadow-gray-300');
      this.$refs.btn_menu.classList.remove('border-black');
      this.$refs.btn_menu.classList.add('border-white');
      this.$refs.modal_menu.classList.add('text-white');
      this.$refs.modal_menu.classList.add('border-white');
      this.$refs.modal_menu.classList.add('shadow-transparent');
    } else {
      // Handle non-bottom scroll position
      //console.log('Scroll position:', scrollY);
      this.$refs.modal_menu.classList.remove('text-white');
      this.$refs.modal_menu.classList.remove('border-white');
      this.$refs.modal_menu.classList.remove('shadow-transparent');
      this.$refs.btn_menu.classList.remove('border-white');
    //   this.$refs.modal_menu.classList.remove('bg-opacity-0');
      this.$refs.btn_menu.classList.add('border-black');
      this.$refs.modal_menu.classList.add('shadow-sm');
      this.$refs.modal_menu.classList.add('shadow-gray-300');
    //   this.$refs.modal_menu.classList.add('bg-slate-50');
    }

}
  });
        },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        
    },
    // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },

    methods:{

        envoyerMessage() {
            const formData = new FormData();
            formData.append('nom', this.nom);
            formData.append('email', this.email);
            formData.append('numero', this.numero);
            formData.append('message', this.message);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/envoyer_message', formData).then(response => {
                this.nom='', this.email='', this.numero='', this.message='';
                this.modalValidation=true;
                console.log('Message envoyer avec succès');
            })
            .catch(error => {(
                console.log(error)
            )});
        },
       
        modal_connection_show:function(){
                if(this.modal_connection){
                    this.modal_connection=false
                    this.modal_back=false
                    
                } else {
                    this.modal_connection=true
                    this.modal_back=true
                }
            },
            togglePassword:function(){
                this.show_password=!this.show_password
            },
            menu_mobile_show:function(){
                this.menu_mobile=!this.menu_mobile
                this.isActive=!this.isActive
            },menu:function(){
                //this.$emit('menu_mobile_show',!this.menu_mobile)
                if(this.height!=='0'){
                    this.height='0'
                } else {
                    this.height='fit-content'
                }
                

            },hide:function(){
                if(this.modal_menu==true){
                    this.modal_menu=false
                }

            }
            ,show:function(){
                if(this.modal_menu==false){
                    this.modal_menu=true
                } else {
                    this.modal_menu=false
                }

            }
            
    }
}
  
</script>
