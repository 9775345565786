<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div class="w-screen  headers overflow-hidden h-fit transition-all duration-200  bg-white" @mouseleave="modal_produit(false),modal_outils(false),modal_marche(false)">
        <div class=" overflow-hidden headers1 py-1 h-fit  w-full">
            <div class="flex justify-between items-center overflow-hidden w-full h-fit  md:px-4">
                <div class=" logo max-md:mx-2">
                    <RouterLink to="/" class="no-underline text-gray-900">
                        <img src="./img/logovf.png" class="w-7 sm:w-10">
                    </RouterLink>
                </div>
                <div class="max-md:hidden md:block">
                    <div class="flex gap-5 justify-around items-center h-fit">
                        <div class="py-2">
                            <div>
                                <span class="no-underline">
                                <RouterLink to="/Client/Accueil2" class="no-underline text-gray-900">
                                    Accueil
                                </RouterLink>
                                </span>
                            </div>
                        </div>
                        <div class="py-2 produit hover:cursor-pointer" @mouseover="modal_produit(true),modal_outils(false),modal_marche(false)">
                            <span class="no-underline">Produit</span>
                        </div>
                        <div class="py-2 no-underline hover:cursor-pointer" @click="verificationCalendrier"><span>Calendrier</span></div>
                        <!--/Client/Calendrier-->
                        <div class="py-2 marche hover:cursor-pointer" @mouseover="modal_marche(true),modal_produit(false),modal_outils(false)">Marché
                        </div>
                        <div class="py-2 outils hover:cursor-pointer" @click="verificationCourbe">
                            Courbe
                        </div>
                    </div>
                </div>
                <div class="flex  items-center justify-end lg:px-4">
                    <div class="">
                        <button @click="logout" class="py-1 px-3 bg-blue-400 hover:bg-blue-500 cursor-pointer  text-white  ">Se deconnecter</button>
                    </div>
                    <div class="md:hidden max-md:mx-2 ">
                        <button @click="menu_mobile_show()" class="active:bg-gray-200 fas fa-bars focus:outline-none fa-2x" v-if="mobile==false"></button>
                        <button @click="menu_mobile_show()" class="active:bg-gray-200 fas fa-times fa-2x px-1" v-else></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal mobile -->
    </div>
    <Transition>
        <div class="fixed top-12 right-0 z-[2] w-full bg-slate-700 text-white" v-show="mobile">
            <div class="w-full px-4 text-left flex flex-col justify-center items-center gap-y-4 my-4">
                <div class="w-full" @click="$router.push('/Client/Accueil2')">Accueil</div>
                <div class="w-full flex justify-between items-center" @click="produit_drop=!produit_drop">
                    <div>Produit</div>
                    <div class="fas fa-angle-down" v-show="produit_drop==false"></div>
                    <div class="fas fa-angle-up" v-show="produit_drop==true"></div>
                </div>
                <div class="w-full flex flex-col justify-center items-start bg-slate-800 px-5 gap-y-2 py-2" v-show="produit_drop">
                    <div @click="$router.push('/Client/Produit')">Culture</div>
                    <div @click="$router.push('/Client/ProduitElevage')">Elevage</div>
                </div>
                <div class="w-full" @click="$router.push('/Client/Calendrier')">Calendrier</div>
                <div class="w-full flex justify-between items-center" @click="marche_drop=!marche_drop">
                    <div>Marché</div>
                    <div class="fas fa-angle-down" v-if="marche_drop==false"></div>
                    <div class="fas fa-angle-up" v-else></div>
                </div>
                <div class="w-full flex flex-col justify-center items-start bg-slate-800 px-5 gap-y-2 py-2" v-show="marche_drop">
                    <div @click="$router.push('/Client/Acteur')">Acteur</div>
                    <div @click="$router.push('/Client/Client')">Client</div>
                    <div @click="$router.push('/Client/Fournisseur')">Fournisseur</div>
                </div>
                <div class="w-full " @click="$router.push('/Client/Courbe')">Courbe</div>
            </div>
        </div>
    </Transition>
   
   

        
        
    <!-- modal mobile -->

<Transition>
    <div class="fixed top-12 right-0 z-[2] w-full bg-slate-700 text-white" v-show="mobile">
        <div class="w-full px-4 text-left flex flex-col justify-center items-center gap-y-4 my-4">
            <div class="w-full" @click="$router.push('/Client/Accueil2')">Accueil</div>
            <div class="w-full flex justify-between items-center" @click="produit_drop=!produit_drop">
                <div>Produit</div>
                <div class="fas fa-angle-down" v-show="produit_drop==false"></div>
                <div class="fas fa-angle-up" v-show="produit_drop==true"></div>
            </div>
            <div class="w-full flex flex-col justify-center items-start bg-slate-800 px-5 gap-y-2 py-2" v-show="produit_drop">
                <div @click="verificationCulture">Culture</div>
                <div @click="$router.push('/Client/ProduitElevage')">Elevage</div>
            </div>
            <div class="w-full" @click="verificationCalendrier">Calendrier</div>
            <div class="w-full flex justify-between items-center" @click="marche_drop=!marche_drop">
                <div>Marché</div>
                <div class="fas fa-angle-down" v-if="marche_drop==false"></div>
                <div class="fas fa-angle-up" v-else></div>
            </div>
            <div class="w-full flex flex-col justify-center items-start bg-slate-800 px-5 gap-y-2 py-2" v-show="marche_drop">
                <div @click="$router.push('/Client/Acteur')">Acteur</div>
                <div @click="$router.push('/Client/Client')">Client</div>
                <div @click="$router.push('/Client/Fournisseur')">Fournisseur</div>
            </div>
            <div class="w-full" @click="verificationCourbe">Courbe</div>

        </div>

    </div>
    </Transition>
    <!-- produit -->
    <div class="modal_produit modal_fixed_5 top-12 hidden bg-white shadow-sm shadow-gray-300 p-4 w-fit h-fit rounded " @mouseover="modal_produit(true)" @mouseleave="modal_produit(false)">
        <div @click="verificationCulture" class="hover:cursor-pointer">
            <h6 class="text-left">Culture</h6>
        </div>
        <!--/Client/Produit-->
        <div @click="verificationElevage" class="hover:cursor-pointer">
            <h6 class="text-left">Elevage</h6>
        </div>
        <!--/Client/ProduitElevage-->
    </div>
    <!-- marche -->
    <div class="modal_marche  modal_fixed_5 top-12 hidden -translate-y-1 p-4 h-fit w-fit bg-white shadow-sm shadow-gray-300 rounded" @mouseover="modal_marche(true)" @mouseleave="modal_marche(false)">
        <div>
            <RouterLink to="/Client/Acteur" class="no-underline text-gray-900">
                <h6 class="text-left">Acteur</h6>
            </RouterLink>
        </div>
        <div>
            <RouterLink to="/Client/Fournisseur" class="no-underline text-gray-900">
                <h6 class="text-left">Fournisseur</h6>
            </RouterLink>
        </div>
        <div>
            <RouterLink to="/Client/Client" class="no-underline text-gray-900">
                <h6 class="text-left">Client</h6>
            </RouterLink>
        </div>
    </div>
    <div class="modal_ajout w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center" v-show="modal1">
        <div class="bg-white p-4">
            <div class="w-full flex justify-start items-center">
                <div>
                    <p>Votre abonnement n'a pas accès à ce type d'information.</p>
                    <p>Vous avez tous les accès dans le marché.</p>
                    <p>Veuillez vous abonnées aux abonnements Standard (20.000Ar) ou Premium (80.000Ar) pour avoir plus des informations. Merci!</p>
                </div>
            </div>
            <div class="w-full flex items-center justify-end">
                <div class="">
                    <button class="px-4 py-1 bg-blue-400 hover:bg-blue-500 text-white" @click="modal1=false">OK</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal_ajout w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center" v-show="modal2">
        <div class="bg-white p-4">
            <div class="w-full flex justify-start items-center">
                <div>
                    <p>Votre abonnement n'a pas accès à ce type d'information.</p>
                    <p>Vous avez tous les accès dans le marché et le produit</p>
                    <p>Veuillez vous abonnées à l'abonement Premium (80.000Ar) pour avoir plus des informations. Merci!</p>
                </div>
            </div>
            <div class="w-full flex items-center justify-end">
                <div class="">
                    <button class="px-4 py-1 bg-blue-400 hover:bg-blue-500 text-white" @click="modal2=false">OK</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .v-enter-active,
    .v-leave-active {
        transition: transform 0.5s ease;
    }
    .v-enter-from,
    .v-leave-to {
        transform: translateX(1000px);
    }
    .headers {
        position: fixed;
        z-index: 4;
        top: 0;
    }
    .modal_item_header {
        position: absolute;
        z-index: 5;
    }
</style>
<script>
    //import { RouterLink } from 'vue-router'
    import '@/components/style/all.css'
    import '@/components/style/header.css'
    import $ from 'jquery'
    //import '@/assets/tailwind.css'
    export default {
        name: 'Header2',
        emits: ['modal_deconnection_show'],
        props: {
            data_mobile: {
                type: Boolean,
            }
        },
        data() {
            return {
                produit_drop: false,
                marche_drop: false,
                modal_deconnection: false,
                mobile: this.$props.data_mobile,
                menu_mobile1: false,
                modal1: false,
                modal2: false,
                typeAbonnement: '',
                btn_deconnection: true,
                toggleProduit: false,
                toggleMarche: false
            }
        },
        mounted() {
            const posProduit = $('.produit').offset();
            $('.modal_produit').offset({
                left: posProduit.left
            })
            const posMarche = $('.marche').offset();
            $('.modal_marche').offset({
                left: posMarche.left
            })
            const posOutils = $('.outils').offset();
            $('.modal_outils').offset({
                left: posOutils.left
            })
            const headers1 = $('.headers1')
            window.addEventListener("scroll", () => {
                const scrollY = window.scrollY;
                if (scrollY < 70) {
                    if (headers1.hasClass('bg-white')) {
                        headers1.removeClass('bg-white')
                    }
                    if (headers1.hasClass('shadow-sm')) {
                        headers1.removeClass('shadow-sm')
                        headers1.removeClass('shadow-gray-300')
                    }
                } else {
                    headers1.addClass('bg-white')
                    headers1.addClass('shadow-sm')
                    headers1.addClass('shadow-gray-300')
                }
            })
        },
        methods: {
            verificationCulture() {
                this.typeAbonnement = localStorage.getItem('typeAbonnement');

                if( this.typeAbonnement === 'basic')
                {

                    this.modal1 = true;
                } else {
                    this.$router.push("/Client/Produit");
                }
            },
            verificationElevage() {
                this.typeAbonnement = localStorage.getItem('typeAbonnement');

                if( this.typeAbonnement === 'basic')
                {

                    this.modal1 = true;
                } else {
                    this.$router.push("/Client/ProduitElevage");
                }
            },
            verificationCalendrier() {
                this.typeAbonnement = localStorage.getItem('typeAbonnement');

                if( this.typeAbonnement === 'basic')
                {

                    this.modal1 = true;
                } else if (this.typeAbonnement === 'standard') {
                    this.modal2 = true;
                } else {
                    this.$router.push("/Client/Calendrier");
                }
            },
            verificationCourbe() {
                this.typeAbonnement = localStorage.getItem('typeAbonnement');

                if( this.typeAbonnement === 'basic')
                {

                    this.modal1 = true;
                } else if (this.typeAbonnement === 'standard') {
                    this.modal2 = true;
                } else {
                    this.$router.push("/Client/Courbe");
                }
            },
            logout() {

            
            //this.$store.commit('clearUser');
            
            // Supprimer une clé spécifique

            localStorage.removeItem('iduser');
            localStorage.removeItem('typeAbonnement');
            localStorage.removeItem('nomUser');
            localStorage.removeItem('emailUser');
            localStorage.removeItem('contactUser');

            // Redirigez l'utilisateur vers la page de connexion ou une autre page appropriée
            this.$router.push('/');

            },
            modal_produit: function(data) {
                const prod = $('.modal_produit')
                if (data) {
                    if (prod.hasClass('hidden')) {
                        prod.removeClass('hidden')
                    }
                } else {
                    if (!prod.hasClass('hidden')) {
                        prod.addClass('hidden')
                    }
                }
            },
            modal_marche: function(data) {
                const prod = $('.modal_marche')
                if (data) {
                    if (prod.hasClass('hidden')) {
                        prod.removeClass('hidden')
                    }
                } else {
                    if (!prod.hasClass('hidden')) {
                        prod.addClass('hidden')
                    }
                }
            },
            modal_outils: function(data) {
                const prod = $('.modal_outils')
                if (data) {
                    if (prod.hasClass('hidden')) {
                        prod.removeClass('hidden')
                    }
                } else {
                    if (!prod.hasClass('hidden')) {
                        prod.addClass('hidden')
                    }
                }
            },
            modal_deconnection_show: function() {
                if (this.modal_deconnection) {
                    this.modal_deconnection = false
                } else {
                    this.modal_deconnection = true
                }
                this.$emit('modal_deconnection_show', this.modal_deconnection)
            },
            modal_marche_show: function() {
                if (this.modal_marche) {
                    this.modal_marche = false
                } else {
                    this.modal_marche = true
                }
            },
            modal_outils_show: function() {
                if (this.modal_outils) {
                    this.modal_outils = false
                } else {
                    this.modal_outils = true
                }
            },
            menu_mobile_show: function() {
                this.mobile = !this.mobile
            },
        }
    }
</script>
