<template>
	<div class=" ">
		<div class="w-screen overflow-x-auto flex justify-center items-center max-sm:px-4 sm:px-16 md:overflow-x-hidden ">
			<div class="w-full">
				<table class="w-full">
				<caption class="sr-only">Pricing plan comparison</caption>
				<thead>
					<tr>
						<th></th>
						<th scope="col " class="max-md:px-5">
							<h2 class="px-2 text-lg font-medium">Basic</h2>
							<p class="mb-3">
								<span class="max-sm:text-sm sm:text-4xl text-gray-900">20.000Ar</span>
								<span class="font-medium text-gray-600">/mois</span>
							</p>
						</th>
						<th scope="col" class="max-md:px-5">
							<h2 class="px-2 text-lg font-medium">Standard</h2>
							<p class="mb-3">
								<span class="max-sm:text-sm sm:text-4xl text-gray-900">40.000Ar</span>
								<span class="font-medium text-gray-600">/mois</span>
							</p>
						</th>
						<th scope="col" class="max-md:px-5">
							<h2 class="px-2 text-lg font-medium">Premium</h2>
							<p class="mb-3">
								<span class="max-sm:text-sm sm:text-4xl text-gray-900">80.000Ar</span>
								<span class="font-medium text-gray-600">/mois</span>
							</p>
						</th>
					</tr>
				</thead>
				<tbody class="space-y-6 text-center divide-y divide-gray-300">
					
					<tr>
						<th scope="row" class="text-left">
							<p class="py-3 max-sm:text-sm text-gray-600">Clients</p>
						</th>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Free plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Standard plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
					</tr>
					<tr>

						
						<th scope="row" class="text-left">
							<p class="py-3 max-sm:text-sm text-gray-600">Fournisseurs</p>
						</th>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Free plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Standard plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
					</tr>
					<tr>

						<th scope="row" class="text-left">
							<p class="py-3 max-sm:text-sm text-gray-600">Acteurs</p>
						</th>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Free plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Standard plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
					</tr>
					<tr>

						<th scope="row" class="text-left">
							<p class="py-3 max-sm:text-sm text-gray-600">Produit d'élevage</p>
						</th>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Not included in Free plan" class="w-5 h-5 mx-auto text-gray-400">
								<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Standard plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
					</tr>

					<tr>

					<th scope="row" class="text-left">
						<p class="py-3 max-sm:text-sm text-gray-600">Produit d'agriculture</p>
					</th>
					<td>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Not included in Free plan" class="w-5 h-5 mx-auto text-gray-400">
							<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
						</svg>
					</td>
					<td>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Standard plan" class="w-5 h-5 mx-auto text-blue-600">
							<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
						</svg>
					</td>
					<td>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
							<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
						</svg>
					</td>
					</tr>



					<tr>
						<th scope="row" class="text-left">
							<p class="py-3 max-sm:text-sm text-gray-600">Calendrier agricole</p>
						</th>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Not included in Free plan" class="w-5 h-5 mx-auto text-gray-400">
								<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Not included in Standard plan" class="w-5 h-5 mx-auto text-gray-400">
								<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-left">
							<p class="py-3 max-sm:text-sm text-gray-600">Courbe</p>
						</th>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Not included in Free plan" class="w-5 h-5 mx-auto text-gray-400">
								<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Not included in Standard plan" class="w-5 h-5 mx-auto text-gray-400">
								<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
					</tr>
				</tbody>
			</table>
			</div>
		</div>
	</div>
</template>